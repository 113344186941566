import React, { useState, useEffect } from "react";
import ApiService from "../../components/ApiService";
import CustomToast from "../../components/CustomToast";
import Grid from "../../components/tables/Grid";
import View from "../../views/view";
import Modal from "../../components/Modal";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const Categories = () => {
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [listener, setListener] = useState(0);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const apiUrl = `/tip`;
  const [categoryData, setCategoryData] = useState({
    id: "",
    name: "",
    coefficient: 0,
    time_limit: 0,
  });
  const columns = [
    { key: "id", title: "Номер" },
    { key: "name", title: "Название" },
    { key: "coefficient", title: "Коэффициент" },
    { key: "time_limit", title: "Ограничение времени" },
  ];
  const detailsConfig = [
    { key: "id", label: "Номер" },
    { key: "name", label: "Название" },
    { key: "coefficient", label: "Коэффициент" },
    { key: "time_limit", label: "Ограничение времени на заявку в минутах" },
    { key: "instruction", label: "Инструкция" },
  ];

  useEffect(() => {
    document.title = "Типы";
    ApiService.setToastHandlers(setToastMessage, setToastType);
  }, []);
  useEffect(() => {
    if (id) setSelectedRow(id);
  }, [id]);

  const setSelectRow = (id) => {
    setSelectedRow(id);
    let currentPath = location.pathname;
    if (!currentPath.endsWith("/") && !currentPath.match(/\/\d+\/?$/)) {
      currentPath += "/";
    }
    // Получаем текущий путь и разделяем его по слешам
    let pathSegments = currentPath.split("/");

    // Убедимся, что у нас есть достаточно сегментов для изменения
    if (pathSegments.length > 1) {
      // Заменяем последний сегмент на новый id
      pathSegments[pathSegments.length - 1] = id;

      // Объединяем сегменты обратно в строку и заменяем текущий путь в истории
      navigate(pathSegments.join("/"), { replace: true });
    }
  };

  const handleInputChange = (e) => {
    const { name, checked, type } = e.target;
    const value = type === "checkbox" ? checked : e.target.value;
    setCategoryData((prevData) => ({ ...prevData, [name]: value }));
  };

  // Функция, вызывающая перерисовку компонента
  const refreshTable = () => {
    setListener((prevKey) => prevKey + 1);
  };

  const openCreate = async () => {
    setCategoryData({
      id: "",
      name: "",
      coefficient: 0,
      time_limit: 0,
      instruction: "",
    });
    setIsCreateModalOpen(true);
  };

  const openUpdate = async () => {
    const response = await ApiService.fetchDataWithToken(`/tip/${selectedRow}`);
    setCategoryData({
      id: response[0].id || null,
      name: response[0].name || "",
      coefficient: response[0].coefficient || 0,
      time_limit: response[0].time_limit || 0,
      instruction: response[0].instruction || "",
    });
    setIsUpdateModalOpen(true);
  };

  const updateDepartament = async () => {
    try {
      await ApiService.postDataWithToken(`/tip/update`, categoryData);
      setToastType("success");
      setToastMessage("Отдел успешно изменен");
      refreshTable();
      setIsUpdateModalOpen(false);
    } catch (error) {}
  };
  const createDepartament = async () => {
    try {
      await ApiService.postDataWithToken(`/tip/add`, categoryData);
      setToastType("success");
      setToastMessage("Отдел успешно сохранен");
      refreshTable();
      setIsCreateModalOpen(false);
    } catch (error) {}
  };

  return (
    <div>
      <div className="grid-view-container">
        <Grid
          listener={listener}
          apiUrl={"/tip/all"}
          columns={columns}
          selectedRow={selectedRow}
          setSelectedRow={setSelectRow}
        />
        <View
          listener={listener}
          selectedId={selectedRow}
          apiUrl={apiUrl}
          detailsConfig={detailsConfig}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginTop: "10px",
            }}
          >
            <button
              style={{ height: "45px" }}
              className="task-table-button"
              onClick={() => {
                openUpdate();
              }}
            >
              Редактировать
            </button>
          </div>
          <div style={{ marginTop: "10px" }} alwaysVisible>
            <button
              style={{ height: "45px" }}
              className="task-table-button"
              onClick={() => {
                openCreate();
              }}
            >
              Создать
            </button>
          </div>
        </View>
      </div>
      <CustomToast
        message={toastMessage}
        type={toastType}
        setMessage={setToastMessage}
        setType={setToastType}
      />
      <Modal active={isUpdateModalOpen} setActive={setIsUpdateModalOpen}>
        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
          Редактор типа {categoryData.id}
        </p>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="text"
            name="name"
            value={categoryData.name}
            onChange={handleInputChange}
            placeholder="Название"
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="number"
            name="coefficient"
            min="0"
            value={categoryData.coefficient}
            onChange={handleInputChange}
            placeholder="Коэффициент"
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="number"
            name="time_limit"
            min="0"
            value={categoryData.time_limit}
            onChange={handleInputChange}
            placeholder="Ограничение времени на заявку в минутах"
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <textarea
            type="text"
            name="instruction"
            value={categoryData.instruction}
            onChange={handleInputChange}
            placeholder="Инстукция"
          />
        </div>
        <button className="button" onClick={updateDepartament}>
          Отправить
        </button>
      </Modal>
      <Modal active={isCreateModalOpen} setActive={setIsCreateModalOpen}>
        <p style={{ fontSize: "18px", fontWeight: "bold" }}>Создание отдела</p>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="text"
            name="name"
            value={categoryData.name}
            onChange={handleInputChange}
            placeholder="Название"
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="number"
            name="coefficient"
            min="0"
            value={categoryData.coefficient}
            onChange={handleInputChange}
            placeholder="Коэффициент"
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <input
            type="number"
            name="time_limit"
            min="0"
            value={categoryData.time_limit}
            onChange={handleInputChange}
            placeholder="Ограничение времени на заявку в минутах"
          />
        </div>
        <div style={{ marginBottom: "10px" }}>
          <textarea
            type="text"
            name="instruction"
            value={categoryData.instruction}
            onChange={handleInputChange}
            placeholder="Инстукция"
          />
        </div>
        <button className="button" onClick={createDepartament}>
          Отправить
        </button>
      </Modal>
    </div>
  );
};

export default Categories;
