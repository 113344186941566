import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ApiService from "../ApiService";
import "../../assets/styles/header.scss";
import defaultAvatar from "../../assets/images/avatar.png";
import axios from "axios";

const apiKey = process.env.REACT_APP_API_KEY;

const AdminHeader = () => {
  const [avatar, setAvatar] = useState(defaultAvatar);
  const location = useLocation();

  useEffect(() => {
    const storedAvatar = localStorage.getItem("avatar");
    if (storedAvatar) {
      setAvatar(storedAvatar);
    } else {
      fetchAvatarFromServer();
    }
  }, []);

  const fetchAvatarFromServer = async () => {
    try {
      const users = await ApiService.fetchDataWithToken("/user/byToken");
      const body = { type: "logo", userId: users.id };
      const token = localStorage.getItem("token");
      const response = await axios.post(`${apiKey}/user/download`, body, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        setAvatar(base64data);
        localStorage.setItem("avatar", base64data);
      };
      reader.readAsDataURL(response.data);
    } catch (error) {
      console.error("Ошибка при загрузке аватара с сервера:", error);
    }
  };

  const handleAvatarChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("userId", localStorage.getItem("userId"));
    formData.append("type", "backgroundImage");
    formData.append("files", file);

    try {
      await ApiService.postDataWithToken("/user/upload", formData);
      localStorage.removeItem("backgroundImage");
      window.location.reload();
    } catch (error) {
      console.error("Ошибка при загрузке файла:", error);
    }
  };

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <header className="custom-header">
      <div className="container-header">
        <Link to="/">
          <svg
            className="logo-neon"
            xmlns="http://www.w3.org/2000/svg"
            width="100"
            height="100"
            viewBox="0 0 37 37"
          >
            <g clipPath="url(#clip0_6_17)">
              <path
                className="path"
                d="M7.52768 11.9735L3.40221 7.77979C1.13192 10.9045 0 14.4783 0 18.5005C0 22.5226 1.13192 26.0955 3.40221 29.2202L7.43173 25.124C6.22325 23.1753 5.61624 21.0034 5.61716 18.6055C5.61624 16.1851 6.25369 13.9747 7.52675 11.9735H7.52768Z"
                fill="#B6E33E"
              />
              <path
                className="path"
                d="M32.9918 7.77979L28.9642 11.8751C30.1726 13.8238 30.7787 15.9975 30.7787 18.3917C30.7769 20.813 30.1422 23.0271 28.8691 25.0283L32.9918 29.2192C35.2621 26.0946 36.3968 22.5198 36.3968 18.4995C36.3968 14.4793 35.2612 10.9045 32.9918 7.77979Z"
                fill="#B6E33E"
              />
              <path
                className="path"
                d="M18.0933 31.2878C15.7354 31.2897 13.5988 30.6736 11.6819 29.4432L7.65234 33.5394C10.7271 35.8473 14.2437 36.9998 18.2003 36.9998C22.157 36.9998 25.6699 35.8464 28.7437 33.5394L24.6182 29.3457C22.6496 30.6398 20.4743 31.2869 18.0942 31.2869L18.0933 31.2878Z"
                fill="#B6E33E"
              />
              <path
                className="path"
                d="M18.1993 0C14.2427 0 10.7261 1.15347 7.65137 3.4604L11.7759 7.65132C13.7445 6.35719 15.9198 5.71013 18.3036 5.71013C20.6873 5.71013 22.798 6.32531 24.715 7.55567L28.7427 3.4604C25.6689 1.15347 22.1541 0 18.1993 0Z"
                fill="#B6E33E"
              />
              <path
                className="path"
                d="M23.6623 9.66305V26.8384H20.8865V20.5816H18.2075L14.1282 26.8384H10.9907L15.2868 20.3359C14.1761 19.9927 13.2629 19.3418 12.547 18.3853C11.8311 17.4288 11.4732 16.3607 11.4732 15.1828C11.4732 13.6458 11.9999 12.3404 13.0544 11.2695C14.1079 10.1985 15.3911 9.66211 16.904 9.66211H23.6623V9.66305ZM16.904 18.0543H20.8865V12.3123H16.904C16.1642 12.3123 15.5359 12.5908 15.0212 13.1469C14.5064 13.703 14.249 14.3819 14.249 15.1837C14.249 15.9855 14.5064 16.6645 15.0212 17.2206C15.5359 17.7767 16.1632 18.0552 16.904 18.0552V18.0543Z"
                fill="#2C7136"
              />
            </g>
          </svg>
        </Link>
        <div className="hamburger-menu">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <nav className={`navbar `}>
          <ul className="nav-list">
            <li className="nav-item">
              <Link
                to="/user"
                className={`nav-link ${
                  location.pathname.startsWith("/user") ? "active" : ""
                }`}
              >
                Личная страница
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/ktasks"
                className={`nav-link ${
                  location.pathname.startsWith("/ktasks") ? "active" : ""
                }`}
              >
                Заявки
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/kpi"
                className={`nav-link ${
                  location.pathname === "/kpi" ? "active" : ""
                }`}
              >
                Рейтинг
              </Link>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link">Статистика</span>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <Link to="/calculation" className="nav-link">
                    Фирмы/Сотрудники
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/workersStatisticTags" className="nav-link">
                    Тэги/Сотрудник
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/companyStatisticTags" className="nav-link">
                    Тэги/Компании
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/companyCategory" className="nav-link">
                    ГЗ Компании
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/companyTime" className="nav-link">
                    Клиент/Время
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/companyCheck" className="nav-link">
                    Отслеживание времени
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/cashboxTaskStatistic" className="nav-link">
                    Полезное
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/workersStatistic" className="nav-link">
                    Посещаемость
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/sheet/holiday" className="nav-link">
                    Отпуска
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/sheet/attendance" className="nav-link">
                    ГЗ месяца
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link">Справочники</span>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <Link to="/company" className="nav-link">
                    Клиенты
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/admin/user" className="nav-link">
                    Сотрудники
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/depart" className="nav-link">
                    Отделы
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/categories" className="nav-link">
                    Типы
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/ofd" className="nav-link">
                    ОФД
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/tariff" className="nav-link">
                    Тарифы
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/enots" className="nav-link">
                    Еноты
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/clientaccess" className="nav-link">
                    Доступы
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link">Связи</span>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <Link to="/projectCategory" className="nav-link">
                    Проекты с типами
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/projectUser" className="nav-link">
                    Проекты с сотрудниками
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/categoryUser" className="nav-link">
                    Тэги с сотрудниками
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link">Трекинг</span>
              <ul className="dropdown-menu">
                <li className="nav-item">
                  <Link to="/tracking" className="nav-link">
                    Трэкинг (1 линия)
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/trackingUser" className="nav-link">
                    Трэкинг который видять сотрудники
                  </Link>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <Link to="/audit" className="nav-link">
                Аудит
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-link">
                Форма
              </Link>
            </li>
          </ul>
        </nav>
        <div className="user-menu">
          <img src={avatar} alt="User Avatar" className="mini-avatar" />
          <ul className="dropdown-user-menu">
            <li className="nav-item">
              <button onClick={logOut}>Выйти</button>
            </li>
            <li className="nav-item">
              <input
                type="file"
                id="avatar-upload"
                onChange={handleAvatarChange}
                style={{ display: "none" }}
              />
              <label htmlFor="avatar-upload" className="file-upload-label">
                Загрузить фон
              </label>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default AdminHeader;
