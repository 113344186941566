import React, { useState, useEffect, useMemo } from "react";
import ApiService from "../../components/ApiService";
import CustomToast from "../../components/CustomToast";
import YearMonthSelector from "../../components/YearMonthSelector";
import AutocompleteInput from "../../components/autoCompliteInputs/FithoutCreate";
import TaskTable from "../../components/tables/TaskTable";

const CalculationCompany = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [tableData, setTableData] = useState({});
  const [toastMessage, setToastMessage] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");
  const [taskUrl, setTaskUrl] = useState("/application/table");
  const [selectedRow, setSelectedRow] = useState(null);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // Месяцы начинаются с 0
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const monthStart = useMemo(
    () => new Date(selectedYear, selectedMonth, 1),
    [selectedYear, selectedMonth]
  );
  const monthEnd = useMemo(
    () => new Date(selectedYear, selectedMonth + 1, 0),
    [selectedYear, selectedMonth]
  );

  useEffect(() => {
    document.title = "Статистика";
    const intervalId = setInterval(() => setCurrentDate(new Date()), 1000);
    const reloadPageTimeout = setTimeout(
      () => window.location.reload(),
      10 * 60 * 1000
    );

    return () => {
      clearInterval(intervalId);
      clearTimeout(reloadPageTimeout);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.fetchDataWithToken(
          "/kpi/calculationCompany",
          { startDate: monthStart, endDate: monthEnd, companyId: companyId }
        );
        setTableData(response || {});
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [monthStart, monthEnd, companyId]);

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  };

  const handleCategory = async (name) => {
    const response = await ApiService.fetchDataWithToken(
      "/kpi/calculationCompanyCat",
      {
        startDate: monthStart,
        endDate: monthEnd,
        companyId: companyId,
        categoryName: name,
      }
    );
    console.log(response);
    setTaskUrl(
      `/application/table?search=${response.uniqueTaskIds}&column=id&page=1&itemsPerPage=100`
    );
    setListener(listener + 1);
  };
  const [listener, setListener] = useState(0);

  const columns = [
    { key: "id", title: "ИД" },
    { key: "createdAt", title: "Дата создания", date: true },
    { key: "deadline", title: "Крайний срок", date: true },
    { key: "finishAt", title: "Дата завершения", date: true },
    { key: "company", title: "Компания" },
    { key: "name", title: "Имя" },
    { key: "category", title: "Тип" },
    { key: "createbyuser", title: "Ответственный" },
    { key: "status", title: "Статус" },
    { key: "priority", title: "Приоритет" },
  ];

  const sortedData = useMemo(() => {
    if (!tableData.kpi) return [];
    const categories = Object.entries(tableData.kpi);
    return categories.sort(([keyA, valueA], [keyB, valueB]) => {
      const aValue = valueA[sortColumn] || 0;
      const bValue = valueB[sortColumn] || 0;

      if (typeof aValue === "number" && typeof bValue === "number") {
        return sortDirection === "asc" ? aValue - bValue : bValue - aValue;
      }

      if (typeof aValue === "string" && typeof bValue === "string") {
        return sortDirection === "asc"
          ? aValue.localeCompare(bValue)
          : bValue.localeCompare(aValue);
      }

      return 0;
    });
  }, [tableData, sortColumn, sortDirection]);
  const setSelectRow = (id) => {
    setSelectedRow(id);
    window.open(`/ktasks/${id}`, "_blank");
  };

  return (
    <div>
      <div style={{ margin: "10px" }}>
        Текущая дата: {currentDate.toLocaleString()}
      </div>
      <div style={{ margin: "10px" }}>
        <YearMonthSelector
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          onYearChange={setSelectedYear}
          onMonthChange={setSelectedMonth}
        />
      </div>
      <AutocompleteInput
        apiUrl={`/firm?page=1&itemsPerPage=5000`}
        name="name"
        placeholder="*Название компании"
        setId={setCompanyId}
        chekValue={companyId}
      />
      <div style={{ display: "flex" }}>
        <div
          className="kpi-tasks-table-container"
          style={{
            width: "35%",
            padding: "10px",
            overflow: "auto",
            height: "82vh",
          }}
        >
          <div className="table-wrapper">
            <table className="table">
              <thead>
                <tr>
                  <th
                    onClick={() => handleSort("сотрудник")}
                    style={{
                      width: "70%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Категория
                  </th>

                  <th
                    onClick={() => handleSort("количество")}
                    style={{
                      width: "10%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Кол-во заявок
                  </th>
                  <th
                    onClick={() => handleSort("сумма")}
                    style={{
                      width: "10%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    Сумма
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map(([key, value], index) => (
                  <React.Fragment key={index}>
                    <tr
                      onClick={() => {
                        handleCategory(key);
                        setCategoryName(key);
                      }}
                      style={{
                        cursor: "pointer",
                        backgroundColor:
                          categoryName === key ? "#f0f8ff" : "transparent",
                      }}
                    >
                      <td colSpan={1}>
                        <strong>{key}</strong>
                      </td>
                      {Object.entries(value).map(([subKey, subValue]) => (
                        <td key={subKey}>{subValue}</td>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="grid-view-container" style={{ width: "80%" }}>
          <TaskTable
            listener={listener}
            apiUrl={taskUrl}
            columns={columns}
            selectedRow={selectedRow}
            setSelectedRow={setSelectRow}
          />
        </div>
      </div>
      <CustomToast
        message={toastMessage}
        type={toastType}
        setMessage={setToastMessage}
        setType={setToastType}
      />
    </div>
  );
};

export default CalculationCompany;
