import {
  START_PAGE,
  USER_PAGE,
  REG_PAGE,
  KPI_PAGE,
  TASK_PAGE,
  KPI_CALCULATION,
  COMPANY_CATEGORY,
  COMPANY_TIME,
  AUTHORIZATION,
  COMPANY_PAGE,
  TASK_PAGE_NOT_ID,
  DIVISION_PAGE,
  COMPANY_PAGE_NOT_ID,
  DIVISION_PAGE_NOT_ID,
  ORG_PAGE,
  ORG_PAGE_NOT_ID,
  NETWORK_EQUIPMENT_PAGE,
  NETWORK_EQUIPMENT_NOT_ID,
  CLIENT_EQUIPMENT_NOT_ID,
  CLIENT_EQUIPMENT_PAGE,
  PC_EQUIPMENT_PAGE,
  PC_EQUIPMENT_NOT_ID,
  TRACKING_PAGE,
  USER_ADMIN_PAGE,
  USER_ADMIN_PAGE_NOT_ID,
  AUDIT,
  USER_PAGE_NOT_ID,
  PROJECT_CATEGORY,
  PROJECT_USER,
  TASK_PROJECT,
  DEPART_PAGE,
  DEPART_PAGE_NOT_ID,
  CATIGORIES_PAGE,
  CATIGORIES_PAGE_NOT_ID,
  OFD_PAGE,
  OFD_PAGE_NOT_ID,
  CASHBOX_PAGE,
  CASHBOX_PAGE_NOT_ID,
  TARIFF_PAGE,
  TARIFF_PAGE_NOT_ID,
  TRACKING_PAGE_NOT_ID,
  TASK_TIME_CHECK,
  TASK_TIME_CHECK_NOT_ID,
  ATTENDANCE_SHEET,
  PC_COMPANY_PAGE,
  PC_COMPANY_PAGE_NOT_ID,
  CASHBOX_COMPANY_PAGE,
  CASHBOX_COMPANY_PAGE_NOT_ID,
  CLIENT_COMPANY_EQUIPMENT_NOT_ID,
  CLIENT_COMPANY_EQUIPMENT_PAGE,
  SERVER_PAGE,
  SERVER_NOT_ID,
  SERVER_COMPANY_NOT_ID,
  SERVER_COMPANY_PAGE,
  ORG_COMPANY_PAGE,
  ORG_COMPANY_PAGE_NOT_ID,
  HOLIDAY_SHEET,
  BUFFER,
  BUFFER_NOT_ID,
  ENOTS,
  CATEGORY_USER,
  COMPANY_CHECK_PAGE,
  COMPANY_CHECK_PAGE_NOT_ID,
  TASK_TIME,
  CLIENT_ACCESS,
  CLIENT_ACCESS_NOT_ID,
  TASK_STATISTIC,
  WORKERS_STATISTIC,
  USER_STATISTIC,
  COMPANY_STATISTIC,
} from "./Consts";

import FormApp from "../pages/formApp";
import TaskGrig from "../pages/taskPages/task";
import Registration from "../pages/auth/registration";
import Kpi from "../pages/kpi/kpi";
import Calculation from "../pages/kpi/calculation";
import CompanyCategory from "../pages/kpi/companyCategory";
import CompanyTime from "../pages/kpi/companyTime";
import Autorization from "../pages/auth/autorization";
import User from "../pages/users/user";
import Company from "../pages/company/company";
import Divizion from "../pages/company/divizion";
import Org from "../pages/company/org";
import NetworkEquipment from "../pages/company/networkEquipment";
import Client from "../pages/company/client";
import PC from "../pages/company/pc";
import TrackingAdmin from "../pages/tracking/trackingAdmin";
import TrackingUser from "../pages/tracking/trackingUser";
import UserAdmin from "../pages/users/userAdmin";
import Audit from "../pages/audit";
import ProjectCategoryLinker from "../pages/project/connectionCategory";
import ProjectUserLinker from "../pages/project/connectionUser";
import Departament from "../pages/catalog/departament";
import Categories from "../pages/catalog/categories";
import TaskTime from "../pages/taskPages/taskTime";
import Cashbox from "../pages/company/cashbox";
import Ofd from "../pages/ofd";
import Tariff from "../pages/tariff";
import Attendance from "../pages/sheet/attendance";
import PCCompany from "../pages/company/pcCompany";
import CashboxCompany from "../pages/company/cashboxCompany";
import ClientCompany from "../pages/company/clientCompany";
import Server from "../pages/company/server";
import ServerCompany from "../pages/company/serverCompany";
import OrgCompany from "../pages/company/orgCompany";
import Holiday from "../pages/sheet/holiday";
import BufferTracking from "../pages/tracking/trackingBuffer";
import Enots from "../pages/kpi/enots";
import CategoryUser from "../pages/project/categoryUsers";
import Check from "../pages/company/check";
import Access from "../pages/company/accesses";
import TaskStatistic from "../pages/kpi/teskStatistic";
import WorkersWork from "../pages/kpi/workersWork";
import CalculationUser from "../pages/kpi/UserKpi";
import CalculationCompany from "../pages/kpi/CompanyKpi";

export const adminRoute = [
  {
    path: KPI_PAGE,
    Component: Kpi,
  },
  {
    path: TASK_PAGE,
    Component: TaskGrig,
  },
  {
    path: TASK_PAGE_NOT_ID,
    Component: TaskGrig,
  },
  {
    path: KPI_CALCULATION,
    Component: Calculation,
  },
  {
    path: COMPANY_CATEGORY,
    Component: CompanyCategory,
  },
  {
    path: COMPANY_TIME,
    Component: CompanyTime,
  },
  {
    path: REG_PAGE,
    Component: Registration,
  },
  {
    path: AUTHORIZATION,
    Component: Autorization,
  },
  {
    path: START_PAGE,
    Component: FormApp,
  },
  {
    path: USER_PAGE,
    Component: User,
  },
  {
    path: USER_PAGE_NOT_ID,
    Component: User,
  },
  {
    path: COMPANY_PAGE,
    Component: Company,
  },
  {
    path: COMPANY_PAGE_NOT_ID,
    Component: Company,
  },
  {
    path: DIVISION_PAGE,
    Component: Divizion,
  },
  {
    path: DIVISION_PAGE_NOT_ID,
    Component: Divizion,
  },
  {
    path: ORG_PAGE,
    Component: Org,
  },
  {
    path: ORG_PAGE_NOT_ID,
    Component: Org,
  },
  {
    path: NETWORK_EQUIPMENT_PAGE,
    Component: NetworkEquipment,
  },
  {
    path: NETWORK_EQUIPMENT_NOT_ID,
    Component: NetworkEquipment,
  },
  {
    path: CLIENT_EQUIPMENT_NOT_ID,
    Component: Client,
  },
  {
    path: CLIENT_EQUIPMENT_PAGE,
    Component: Client,
  },
  {
    path: PC_EQUIPMENT_NOT_ID,
    Component: PC,
  },
  {
    path: PC_EQUIPMENT_PAGE,
    Component: PC,
  },
  {
    path: PC_COMPANY_PAGE,
    Component: PCCompany,
  },
  {
    path: PC_COMPANY_PAGE_NOT_ID,
    Component: PCCompany,
  },
  {
    path: CLIENT_COMPANY_EQUIPMENT_NOT_ID,
    Component: ClientCompany,
  },
  {
    path: CLIENT_COMPANY_EQUIPMENT_PAGE,
    Component: ClientCompany,
  },
  {
    path: CASHBOX_COMPANY_PAGE,
    Component: CashboxCompany,
  },
  {
    path: CASHBOX_COMPANY_PAGE_NOT_ID,
    Component: CashboxCompany,
  },
  {
    path: TRACKING_PAGE,
    Component: TrackingAdmin,
  },
  {
    path: TRACKING_PAGE_NOT_ID,
    Component: TrackingAdmin,
  },
  {
    path: USER_ADMIN_PAGE,
    Component: UserAdmin,
  },
  {
    path: USER_ADMIN_PAGE_NOT_ID,
    Component: UserAdmin,
  },
  {
    path: AUDIT,
    Component: Audit,
  },
  {
    path: PROJECT_CATEGORY,
    Component: ProjectCategoryLinker,
  },
  {
    path: PROJECT_USER,
    Component: ProjectUserLinker,
  },
  {
    path: TASK_PROJECT,
    Component: TrackingUser,
  },
  {
    path: DEPART_PAGE,
    Component: Departament,
  },
  {
    path: DEPART_PAGE_NOT_ID,
    Component: Departament,
  },
  {
    path: CATIGORIES_PAGE,
    Component: Categories,
  },
  {
    path: CATIGORIES_PAGE_NOT_ID,
    Component: Categories,
  },
  {
    path: TASK_TIME_CHECK,
    Component: TaskTime,
  },
  {
    path: TASK_TIME_CHECK_NOT_ID,
    Component: TaskTime,
  },
  {
    path: TASK_TIME,
    Component: TaskTime,
  },
  {
    path: CASHBOX_PAGE,
    Component: Cashbox,
  },
  {
    path: CASHBOX_PAGE_NOT_ID,
    Component: Cashbox,
  },
  {
    path: OFD_PAGE,
    Component: Ofd,
  },
  {
    path: OFD_PAGE_NOT_ID,
    Component: Ofd,
  },
  {
    path: TARIFF_PAGE,
    Component: Tariff,
  },
  {
    path: TARIFF_PAGE_NOT_ID,
    Component: Tariff,
  },
  {
    path: ATTENDANCE_SHEET,
    Component: Attendance,
  },
  {
    path: SERVER_PAGE,
    Component: Server,
  },
  {
    path: SERVER_NOT_ID,
    Component: Server,
  },
  {
    path: SERVER_COMPANY_NOT_ID,
    Component: ServerCompany,
  },
  {
    path: SERVER_COMPANY_PAGE,
    Component: ServerCompany,
  },
  {
    path: ORG_COMPANY_PAGE,
    Component: OrgCompany,
  },
  {
    path: ORG_COMPANY_PAGE_NOT_ID,
    Component: OrgCompany,
  },
  {
    path: HOLIDAY_SHEET,
    Component: Holiday,
  },
  {
    path: BUFFER,
    Component: BufferTracking,
  },
  {
    path: BUFFER_NOT_ID,
    Component: BufferTracking,
  },
  {
    path: ENOTS,
    Component: Enots,
  },
  {
    path: CATEGORY_USER,
    Component: CategoryUser,
  },
  {
    path: COMPANY_CHECK_PAGE,
    Component: Check,
  },
  {
    path: COMPANY_CHECK_PAGE_NOT_ID,
    Component: Check,
  },
  {
    path: CLIENT_ACCESS,
    Component: Access,
  },
  {
    path: CLIENT_ACCESS_NOT_ID,
    Component: Access,
  },
  {
    path: TASK_STATISTIC,
    Component: TaskStatistic,
  },
  {
    path: WORKERS_STATISTIC,
    Component: WorkersWork,
  },
  {
    path: USER_STATISTIC,
    Component: CalculationUser,
  },
  {
    path: COMPANY_STATISTIC,
    Component: CalculationCompany,
  },
];

export const guestRoutes = [
  {
    path: REG_PAGE,
    Component: Registration,
  },
  {
    path: AUTHORIZATION,
    Component: Autorization,
  },
];
