import React, { useEffect, useState, useRef } from "react";
import ApiService from "../components/ApiService";
import pdf from "../assets/images/icons/PDF.png";
import dock from "../assets/images/icons/DOC.png";
import xls from "../assets/images/icons/XLS.png";
import def from "../assets/images/icons/__SATORI.png";

const ViewComponent = ({
  selectedId,
  apiUrl,
  detailsConfig,
  children,
  listener,
  refreshTaskTable,
  pause,
}) => {
  const [itemData, setItemData] = useState(null);
  const [isMobileViewOpen, setMobileViewOpen] = useState(false);
  const [touchStartX, setTouchStartX] = useState(null);
  const [showArrow, setShowArrow] = useState(false);
  const [touchDistance, setTouchDistance] = useState(false);
  const viewRef = useRef(null);
  const [showLegend, setShowLegend] = useState(false);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.key === "Escape") {
        setMobileViewOpen(false);
      }
    };

    document.addEventListener("keydown", handleEsc);

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, []);

  useEffect(() => {
    if (selectedId) {
      const fetchData = async () => {
        try {
          const data = await ApiService.fetchDataWithToken(
            `${apiUrl}/${selectedId}`
          );
          console.log(data);
          setItemData(data[0]);
          setMobileViewOpen(true); // Открываем view-container при выборе строки
        } catch (error) {
          console.error("Ошибка при загрузке данных:", error);
          setItemData(null);
        }
      };
      fetchData();
      setMobileViewOpen(true);
    } else {
      setItemData(null);
      setMobileViewOpen(false); // Закрываем view-container если строка не выбрана
    }
  }, [selectedId, apiUrl, listener]);

  const PauseResumeHandler = async (id, pauseId) => {
    const body = {
      id: id,
      idPause: pauseId,
    };
    try {
      if (pauseId) {
        await ApiService.postDataWithToken("/application/resume", body);
      }
      if (!pauseId) {
        await ApiService.postDataWithToken("/application/pause", body);
      }
    } catch (error) {
      refreshTaskTable();
    }
    refreshTaskTable();
  };

  const getFileIcon = (filename) => {
    if (!filename) {
      return def;
    }

    const extension = filename.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return pdf;
      case "doc":
      case "docx":
        return dock;
      case "xls":
      case "xlsx":
        return xls;
      default:
        return def;
    }
  };

  const handleTouchStart = (event) => {
    setTouchStartX(event.touches[0].clientX);
  };

  const handleTouchMove = (event) => {
    const touchEndX = event.touches[0].clientX;
    setTouchDistance(touchStartX - touchEndX);
    if (touchDistance < -200) {
      // Если свайп справа налево, закрываем view-container
    }
    if (touchDistance < -20) {
      // Если начат свайп влево, показываем стрелочку
      setShowArrow(true);
    }
  };

  const handleTouchEnd = () => {
    if (touchDistance < -150) {
      setMobileViewOpen(false);
    }
    setTouchDistance(0);
    setShowArrow(false);
  };

  if (!selectedId) {
    const filteredChildren = React.Children.map(children, (child) =>
      React.isValidElement(child) && child.props.alwaysVisible ? child : null
    );

    return (
      <div className="view-container">
        <p>Выберите элемент для просмотра деталей.</p>
        {filteredChildren}
      </div>
    );
  }

  if (!itemData) {
    return (
      <div
        ref={viewRef}
        className={`view-container ${isMobileViewOpen ? "open" : ""}`}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px",
          }}
          className="loading-spinner"
        >
          <div className="spinner"></div>
        </div>
      </div>
    );
  }

  const handleFileClick = async (fileId) => {
    await downloadFile(fileId);
  };

  const downloadFile = async (fileId) => {
    try {
      const response = await ApiService.downloadFile(
        `/application/download/${fileId}`
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `file_${fileId}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Ошибка скачивания файла:", error);
    }
  };

  const formatDateTime = (value) => {
    const date = new Date(value);
    if (date.getTime() === 0) {
      return "не указано";
    } else {
      const options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      };
      return date.toLocaleDateString(undefined, options);
    }
  };

  return (
    <div
      ref={viewRef}
      className={`view-container ${isMobileViewOpen ? "open" : ""}`}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <h2>Детали:</h2>
        <div
          className={`mobile-arrow ${showArrow ? "show" : ""} ${
            touchDistance < -150 ? "stroke" : ""
          }`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z" />
          </svg>
        </div>
        <div className="details">
          {detailsConfig.map(
            (detail, index) =>
              itemData[detail.key] != null &&
              itemData[detail.key] !== "" && (
                <div key={detail.key} className={`column `}>
                  {detail.instruction ? (
                    <div className="icon-container">
                      <i
                        className="fas fa-info-circle instruction-icon"
                        style={{ cursor: "pointer", fontSize: "24px" }}
                        aria-hidden="true"
                      ></i>
                      <div className="tooltip-text">{itemData[detail.key]}</div>
                    </div>
                  ) : (
                    <div className="content">
                      <strong>{detail.label}:</strong>
                      <div className="text-container">
                        {detail.date
                          ? formatDateTime(itemData[detail.key])
                          : itemData[detail.key]}
                        <div className="full-text">
                          {detail.date
                            ? formatDateTime(itemData[detail.key])
                            : itemData[detail.key]}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )
          )}
          {itemData.originalnames &&
            itemData.originalnames.length > 0 &&
            itemData.originalnames[0] != null && (
              <div className="file-contanier">
                <strong
                  className={showLegend ? "active" : ""}
                  onClick={() => setShowLegend(!showLegend)}
                >
                  Файлы
                </strong>
                <div className={`file-legend ${showLegend ? "active" : ""}`}>
                  {itemData.originalnames.map((filename, fileIndex) => {
                    const isImage = ["jpg", "jpeg", "png"].includes(
                      filename.split(".").pop().toLowerCase()
                    );
                    return isImage ? (
                      <div className="file-images">
                        <div key={fileIndex} className="image-container">
                          <img
                            src={itemData.fileData[fileIndex]} // Это base64 изображение
                            alt="file-preview"
                            className="file-image"
                            onClick={() =>
                              handleFileClick(itemData.filesId[fileIndex])
                            } // Скачивание при клике
                          />
                        </div>
                      </div>
                    ) : (
                      <ul className="file-icons">
                        <li key={fileIndex} className="file-item">
                          <img
                            src={getFileIcon(filename)} // Отображаем иконку
                            alt="file-icon"
                            className="file-icon"
                            onClick={() =>
                              handleFileClick(itemData.filesId[fileIndex])
                            } // Скачивание при клике
                          />
                          <p
                            className="file-name"
                            onClick={() =>
                              handleFileClick(itemData.filesId[fileIndex])
                            }
                          >
                            {filename}
                          </p>
                        </li>
                      </ul>
                    );
                  })}
                </div>
              </div>
            )}
        </div>
        {!pause ||
        !itemData.status ||
        itemData.status === "Завершена" ||
        itemData.status === "Новая" ? (
          <></>
        ) : (
          <button
            style={{ margin: "5px", height: "41px" }}
            onClick={() => {
              PauseResumeHandler(itemData.id, itemData.pauseId);
            }}
          >
            {itemData.pauseId ? "Возобновить" : "Приостановить"}
          </button>
        )}
        {children}
      </div>
    </div>
  );
};

export default ViewComponent;
