import axios from 'axios';
const wsKey = process.env.REACT_APP_WS_API_KEY;
const apiKey = process.env.REACT_APP_API_KEY;


class ApiService {

    static setToastMessage = null;
    static setToastType = null;
    static socket = new Map();


    static setToastHandlers(setMessage, setType) {
        ApiService.setToastMessage = setMessage;
        ApiService.setToastType = setType;
    }

    static async fetchDataWithToken(url, params) {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(apiKey + url, {
                params: params,
                headers: { Authorization: `Bearer ${token}` }
            });
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Перенаправляем пользователя
                window.location.href = 'https://crm.f1admin.ru/authorization';
                localStorage.setItem('role', '');
            } else if (error.response && error.response.status === 400) {
                localStorage.setItem('token', error.response.data.accessToken);
                localStorage.setItem('role', error.response.data.role);
                return this.fetchDataWithToken(url, params);
            } else {
                // Если возникает ошибка, вызываем функции для установки сообщения и типа уведомления
                ApiService.setToastType('error');
                ApiService.setToastMessage(error.response.data.message);
                throw error;
            }
        }
    }

    static async postDataWithToken(url, data) {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(apiKey + url, data, {
                headers: { Authorization: `Bearer ${token}` }
            });
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Перенаправляем пользователя на страницу с задачами
                window.location.href = 'https://crm.f1admin.ru/authorization';
                localStorage.setItem('role', '');
            } else if (error.response && error.response.status === 400) {
                localStorage.setItem('token', error.response.data.accessToken);
                localStorage.setItem('role', error.response.data.role);
                return this.postDataWithToken(url, data);
            } else {
                ApiService.setToastType('error');
                ApiService.setToastMessage(error.response.data.message);
                throw error;
            }
        }
    }

    static async formDataWithToken(url, formData) {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(apiKey + url, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data' // Установка заголовка Content-Type для FormData
                }
            });
            return response.data;
        } catch (error) {
            if (error.response && error.response.status === 401) {
                // Перенаправляем пользователя на страницу с задачами
                window.location.href = 'https://crm.f1admin.ru/authorization';
                localStorage.setItem('role', '');
            } else if (error.response && error.response.status === 400) {
                localStorage.setItem('token', error.response.data.accessToken);
                localStorage.setItem('role', error.response.data.role);
                return this.postDataWithToken(url, formData);
            } else {
                // Если возникает ошибка, вызываем функции для установки сообщения и типа уведомления
                console.log(error)
                ApiService.setToastType('error');
                ApiService.setToastMessage(error.response.data.message);
                throw error;
            }
        }
    }

    static async downloadFile(url) {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(apiKey + url, {
                responseType: 'blob',
                headers: { Authorization: `Bearer ${token}` }
            });
            const contentDisposition = response.headers['content-disposition'];
            let filename = '';

            // Проверяем наличие подстроки "filename*=UTF-8''" в заголовке
            if (contentDisposition && contentDisposition.includes('filename*=UTF-8\'\'')) {
                // Получаем закодированное имя файла после подстроки "filename*=UTF-8''"
                const encodedFilename = contentDisposition.split("filename*=UTF-8''")[1];

                // Декодируем закодированное имя файла
                filename = decodeURIComponent(encodedFilename);
            }
            // Создаем ссылку для скачивания
            const uri = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = uri;
            link.setAttribute('download', filename); // Устанавливаем имя файла для скачивания
            document.body.appendChild(link);
            link.click(); // Программно кликаем по ссылке чтобы скачать файл

            // Очищаем
            window.URL.revokeObjectURL(uri);
            link.remove();
        } catch (error) {
            console.log(error)
            if (error.response && error.response.status === 401) {
                // Перенаправляем пользователя
                window.location.href = 'https://crm.f1admin.ru/authorization';
                localStorage.setItem('role', '');
            } else if (error.response && error.response.status === 400) {
                localStorage.setItem('token', error.response.data.accessToken);
                localStorage.setItem('role', error.response.data.role);
                return this.downloadFile(url);
            } else {
                // Если возникает ошибка, вызываем функции для установки сообщения и типа уведомления
                ApiService.setToastType('error');
                ApiService.setToastMessage(error.response.data.message);
                throw error;
            }
        }
    }
    static async downloadFlyFile(url) {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(apiKey + url, {
                responseType: 'blob',
                headers: { Authorization: `Bearer ${token}` }
            });

            // Более надежное извлечение имени файла из заголовка `content-disposition`
            const contentDisposition = response.headers['content-disposition'];
            let filename = 'download.docx'; // Значение по умолчанию на случай, если имя файла не найдено
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename="?([^"]+)"?/i);
                if (matches && matches.length > 1) {
                    filename = matches[1];
                }
            }

            // Создаем URL из полученного Blob и инициируем скачивание файла
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const uri = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = uri;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();

            // Очистка после скачивания
            setTimeout(() => { // Задержка для обеспечения завершения скачивания
                window.URL.revokeObjectURL(uri);
                link.remove();
            }, 100);
        } catch (error) {
            console.log(error)
            if (error.response && error.response.status === 401) {
                // Перенаправляем пользователя
                window.location.href = 'https://crm.f1admin.ru/authorization';
                localStorage.setItem('role', '');
            } else if (error.response && error.response.status === 400) {
                localStorage.setItem('token', error.response.data.accessToken);
                localStorage.setItem('role', error.response.data.role);
                return this.downloadFile(url);
            } else {
                // Если возникает ошибка, вызываем функции для установки сообщения и типа уведомления
                ApiService.setToastType('error');
                ApiService.setToastMessage(error.response.data.message);
                throw error;
            }
        }
    }
    static async postDataWithOutToken(url, data) {
        try {
            const response = await axios.post(apiKey + url, data, {
            });
            return response.data;
        } catch (error) {
            // Если возникает ошибка, вызываем функции для установки сообщения и типа уведомления
            ApiService.setToastType('error');
            console.log(error)
            ApiService.setToastMessage(error.response.data.message);
            throw error;
        }
    }
    static async fetchDataWithBitrix(url, params) {
        try {

            const response = await axios.get(url, {
                params: params,
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
    // Метод для подключения к веб-сокету
    static messageHandlers = new Map(); // Хранение колбэков по endpoint'ам

    static connectWebSocket(endPoint, onMessageCallback) {
        const token = localStorage.getItem('token');

        // Проверяем, подключен ли уже WebSocket для данного endpoint
        if (ApiService.socket.has(endPoint)) {
            const existingSocket = ApiService.socket.get(endPoint);
            if (existingSocket.readyState === WebSocket.OPEN) {
                // Добавляем новый колбэк
                const handlers = ApiService.messageHandlers.get(endPoint) || [];
                if (!handlers.includes(onMessageCallback)) {
                    handlers.push(onMessageCallback);
                    ApiService.messageHandlers.set(endPoint, handlers);
                } else {
                    console.log(`Handler for endpoint ${endPoint} already exists`);
                }
                return;
            } else if (existingSocket.readyState === WebSocket.CONNECTING) {
                // Если WebSocket еще находится в процессе подключения, ждем его открытия
                const handlers = ApiService.messageHandlers.get(endPoint) || [];
                if (!handlers.includes(onMessageCallback)) {
                    handlers.push(onMessageCallback);
                    ApiService.messageHandlers.set(endPoint, handlers);
                } else {
                    console.log(`Handler for endpoint ${endPoint} already exists`);
                }
                return;
            } else {
                // Если WebSocket закрыт или в ошибочном состоянии, создаем новое соединение
                console.log(`WebSocket is closed or in error state for endpoint: ${endPoint}`);
            }
        }

        // Если WebSocket соединение не существует или не открыто, создаем новое
        const socket = new WebSocket(wsKey + endPoint);

        socket.onopen = () => {
            socket.send(JSON.stringify({ type: 'auth', token: token }));
        };

        socket.onmessage = (event) => {
            const data = JSON.parse(event.data);
            // Вызов колбэков только для текущего endpoint
            const handlers = ApiService.messageHandlers.get(endPoint) || [];
            handlers.forEach(handler => handler(data));
        };

        socket.onclose = () => {
            ApiService.socket.delete(endPoint);
            setTimeout(() => {
                ApiService.connectWebSocket(endPoint, onMessageCallback); // Попытка переподключения
            }, 3000);
        };

        socket.onerror = (error) => {
            console.error('WebSocket error:', error);
        };

        // Сохраняем новое соединение
        ApiService.socket.set(endPoint, socket);

        // Добавляем или обновляем колбэк для конкретного endpoint
        if (!ApiService.messageHandlers.has(endPoint)) {
            ApiService.messageHandlers.set(endPoint, []);
        }
        const handlers = ApiService.messageHandlers.get(endPoint);
        if (!handlers.includes(onMessageCallback)) {
            handlers.push(onMessageCallback);
        } else {
            console.log(`Handler for endpoint ${endPoint} already exists`);
        }
    }

}

export default ApiService;
