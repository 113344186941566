import React, { useState, useEffect, useRef } from "react";
import ApiService from "../../components/ApiService";
import CustomToast from "../../components/CustomToast";
import View from "../../views/view";
import SmallMenu from "../../components/smallMenu";
import Modal from "../../components/Modal";
import AutocompleteInput from "../../components/autoCompliteInputs/FithoutCreate";
import Chat from "../../components/chat";
import TaskTable from "../../components/tables/TaskTable";
import { useNavigate, useParams } from "react-router-dom";
import WorkLoadComponent from "../../components/workLoad";
import TaskFinishStatistic from "../../components/taskFinishStatistic";
import CustomSelect from "../../components/customSelect";
import SideModal from "../../components/SideModal";
import CheckList from "../../components/CheckList";
import Grid from "../../components/tables/Grid";
const TaskGrid = () => {
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [userItems, setUserItems] = useState([]);
  const [modalActive, setModalActive] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [commentInput, setCommentInput] = useState("");
  const [description, setDescription] = useState("");
  const [statistic, setStatistic] = useState(false);
  const [openDeparture, setOpenDeparture] = useState(false);
  const [departureUrl, setDepartureUrl] = useState(false);
  const [projectIdInput, setProjectIdInput] = useState("");
  const [firmIdInput, setFirmIdInput] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [priorityName, setPriorityName] = useState("");
  const [usrName, setUsrName] = useState("");
  const [priorityIdInput, setPriorityIdInput] = useState("");
  const [tipIdName, setTipName] = useState("");
  const [projectName, setProjectName] = useState("");
  const [tipIdInput, setTipIdInput] = useState("");
  const [userId, setUserId] = useState(null);
  const [isFocus, setFocus] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [deadline, setDeadline] = useState(null);
  const [newDeadline, setNewDeadline] = useState(null);
  const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [listener, setListener] = useState(0);
  const [openModelDeadline, setOpenModelDeadline] = useState(false);
  const [workLoad, setWorkLoad] = useState([]);
  const [divisionId, setDivisionId] = useState(null);
  const [ORGId, setORGId] = useState(null);
  const [ORGName, setORGName] = useState("");
  const [completion_time, setCompletion_time] = useState(null);
  const [PCId, setPCId] = useState(null);
  const [divisionName, setDivisionName] = useState("");
  const [isPutOffModalOpen, setIsPutOffModalOpen] = useState("");
  const [PCName, setPCName] = useState("");
  const [checkListOpenModal, setCheckListOpenModal] = useState(false);
  const [taskUrl, setTaskUrl] = useState("/application/table");
  const navigate = useNavigate();

  const [serviceData, setServiceData] = useState([]);
  const [isServiceModalOpen, setIsServiceModalOpen] = useState("");

  const [isViewOpen, setIsViewOpen] = useState(false);
  const role = localStorage.getItem("role");
  const textareaRef1 = useRef(null);
  const textareaRef2 = useRef(null);

  useEffect(() => {
    setOpenDeparture(false);
  }, [selectedRow]);

  useEffect(() => {
    if (textareaRef1.current) {
      textareaRef1.current.style.height = "auto"; // Сбрасываем высоту до автоматической
      const scrollHeight = textareaRef1.current.scrollHeight;
      const maxHeight = parseInt(
        window.getComputedStyle(textareaRef1.current).maxHeight
      );
      textareaRef1.current.style.height = `${Math.min(
        scrollHeight,
        maxHeight
      )}px`; // Устанавливаем высоту в зависимости от содержимого и максимальной высоты
    }
    if (textareaRef2.current) {
      textareaRef2.current.style.height = "auto"; // Сбрасываем высоту до автоматической
      const scrollHeight = textareaRef2.current.scrollHeight;
      const maxHeight = parseInt(
        window.getComputedStyle(textareaRef2.current).maxHeight
      );
      textareaRef2.current.style.height = `${Math.min(
        scrollHeight,
        maxHeight
      )}px`; // Устанавливаем высоту в зависимости от содержимого и максимальной высоты
    }
  }, [commentInput]);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      setSelectedRow(id);
      openUpdate(id);
      setIsViewOpen(true);
    }
    const fetchData = async () => {
      try {
        const response = await ApiService.fetchDataWithToken(
          "/depart/workLoad"
        );
        setWorkLoad(response);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [id]);

  const columns = [
    { key: "id", title: "ИД" },
    { key: "createdAt", title: "Дата создания", date: true },
    { key: "deadline", title: "Крайний срок", date: true },
    { key: "finishAt", title: "Дата завершения", date: true },
    { key: "company", title: "Компания" },
    { key: "name", title: "Имя" },
    { key: "category", title: "Тип" },
    { key: "createbyuser", title: "Ответственный" },
    { key: "status", title: "Статус" },
    { key: "priority", title: "Приоритет" },
  ];

  const detailsConfig = [
    { key: "id", label: "ИД" },
    { key: "name", label: "Имя" },
    { key: "description", label: "Описание" },
    { key: "status", label: "Статус" },
    { key: "company", label: "Компания" },
    { key: "project", label: "Отдел" },
    { key: "createbyuser", label: "Ответственный" },
    { key: "category", label: "Тип" },
    { key: "priority", label: "Приоритет" },
    { key: "adres", label: "Адрес филиала" },
    { key: "number", label: "Номер ПК" },
    { key: "anyDesk", label: "Деск" },
    { key: "preliminaryCost", label: "Предворительная стоимость" },
    { key: "completion_time", label: "Время выполнения заявки" },
    { key: "deadline", label: "Крайний срок", date: true },
    { key: "createdAt", label: "Дата создания", date: true },
    { key: "instruction", label: "Инструкция", instruction: true },
  ];

  const setSelectRow = (id, deadline, status) => {
    setSelectedRow(id, deadline, status);
    let parsedDeadline = null;
    if (deadline) {
      parsedDeadline = new Date(deadline); // Преобразование строки даты в объект Date
    }
    setDeadline(parsedDeadline);
    openUpdate(id);
    navigate(`/ktasks/${id}`);

    setIsViewOpen(true);
  };

  const prevIsViewOpenRef = useRef(isViewOpen);

  useEffect(() => {
    if (prevIsViewOpenRef.current && !isViewOpen) {
      setSelectedRow(null);
      navigate("/ktasks"); // Укажите нужный путь
    }
    // Сохранение текущего состояния для следующего рендера
    prevIsViewOpenRef.current = isViewOpen;
  }, [isViewOpen, navigate]);

  // Функция, вызывающая перерисовку компонента
  const refreshTaskTable = () => {
    setListener((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    document.title = "Заявки";
    ApiService.setToastHandlers(setToastMessage, setToastType);
    const fetchData = async () => {
      try {
        const response = await ApiService.fetchDataWithToken(
          "/depart/workLoad"
        );
        setWorkLoad(response);
      } catch (error) {
        console.log(error);
      }
    };
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleFinishSubmit = async () => {
    try {
      const user = await ApiService.fetchDataWithToken("/user/byToken", {});
      const body = {
        id: selectedRow,
        comment: commentInput,
        completion_time: completion_time,
        userId: user.id,
      };
      const response = await ApiService.postDataWithToken(
        "/application/commentAndFinish",
        body
      );
      setToastType("success");
      setToastMessage(response.message);
      refreshTaskTable();
      setIsFinishModalOpen(false);
    } catch (e) {}
  };

  const handleFinishOpen = async () => {
    try {
      const task = await ApiService.fetchDataWithToken("/application", {
        taskId: selectedRow,
      });
      setCompletion_time(task.completion_time || null);
      setIsFinishModalOpen(true);
    } catch (e) {}
  };

  const handleUserSelect = async (userId) => {
    try {
      if (userId != "") {
        const body = {
          id: selectedRow,
          userId: userId,
        };
        const delegate = await ApiService.postDataWithToken(
          "/application/messageToUser",
          body
        );
        setToastType("success");
        setToastMessage(delegate.message);
        refreshTaskTable();
      }
    } catch (error) {
      setToastType("error");
      setToastMessage(error.response.data.message);
      refreshTaskTable();
    }
  };

  const openUpdate = async (selectedRow) => {
    try {
      const response = await ApiService.fetchDataWithToken("/application", {
        taskId: selectedRow,
      });
      const company = response?.company || {};
      const project = response?.project || {};
      const category = response?.category || {};
      const division = response?.division || {};
      setProjectIdInput(response?.projectId || null);
      setNameInput(response?.name || "");
      setTipIdInput(response?.categoryId || null);
      setDivisionId(response?.divisionId || null);
      setPCId(response?.pcId || null);
      setDivisionName(division.adres || "");
      setDescription(response?.description || "");
      setPriorityName(response?.priority || "");
      setCompanyName(company.name || "");
      setProjectName(project.name || "");
      setTipName(category.name || "");
      setFirmIdInput(response?.companyId || null);
      setIsChecked(response?.oneTime || false);
      const user = await ApiService.fetchDataWithToken("/user/byProject", {
        projectId: response?.projectId || "",
      });
      setUserItems(user);
    } catch {}
  };

  const updateTask = async () => {
    const body = {
      taskId: selectedRow,
      name: nameInput,
      description: description,
      priority: priorityName,
      projectId: projectIdInput,
      companyId: firmIdInput,
      divisionId: divisionId,
      pcId: PCId,
      ORGId: ORGId,
      categoryId: tipIdInput,
      companyName: companyName,
      tipName: tipIdName,
      usrName: usrName,
      projectName: projectName,
      categoryName: tipIdName,
      oneTime: isChecked,
    };
    try {
      if (!isChecked) {
        await ApiService.postDataWithToken("/application/update", body);
      } else {
        await ApiService.postDataWithToken("/application/updateOneTime", body);
      }
      setToastType("success");
      setToastMessage("Заявка успешно изменена");
      refreshTaskTable();
      setModalActive(false);
      if (
        [73, 46, 62, 64, 72, 3, 65, 63].includes(tipIdInput) &&
        openDeparture !== true
      ) {
        setDepartureUrl(
          `/application/table?&search=${companyName}&column=companyFullName&search=${[
            42, 49, 52, 58, 43, 44, 60, 45, 53, 63, 62, 65, 26, 70, 74, 75, 74,
            28, 3, 8, 9, 10, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
            26, 11, 12, 32, 39, 41, 40, 29, 31, 30, 33, 34, 35, 36, 37, 38, 20,
            34, 35, 33, 46, 47, 47, 15, 32, 39, 41, 48, 50, 51, 52, 54, 54, 48,
            16, 56, 56, 57, 58,
          ]}&column=categoryId&status=%D0%BD%D0%B5+%D0%B7%D0%B0%D0%B2%D0%B5%D1%80%D1%88%D0%B5%D0%BD%D0%B0`
        );
        setOpenDeparture(true);
      }
    } catch (error) {}
  };

  const deleteTask = async () => {
    const body = {
      taskId: selectedRow,
    };
    try {
      await ApiService.postDataWithToken("/application/delete", body);
      refreshTaskTable();
    } catch (error) {}
  };

  const resumeTask = async () => {
    const body = {
      taskId: selectedRow,
    };
    try {
      await ApiService.postDataWithToken(
        "/application/resumeCompletedTask",
        body
      );
      refreshTaskTable();
    } catch (error) {}
  };

  const openUpdateDeadline = () => {
    setCommentInput("");
    setOpenModelDeadline(true);
    setNewDeadline(deadline);
  };

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;
    const dateObject = new Date(selectedDate);
    setNewDeadline(dateObject);
  };

  const formatDate = (date) => {
    if (!date) return "";

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const handleUpdateDeadline = async () => {
    try {
      const bodyDeadline = {
        id: selectedRow,
        deadline: newDeadline,
        comment: commentInput,
      };
      await ApiService.postDataWithToken(
        "/application/setDeadline",
        bodyDeadline
      );
      setToastType("success");
      setToastMessage("Крайний срок изменен");
      setOpenModelDeadline(false);
      setCommentInput("");
      refreshTaskTable();
    } catch (e) {
      // Обработка ошибки
    }
  };

  const detailsWorkLoad = [
    { key: "project", label: "Отделы", column: "projectid" },
    { key: "users", label: "Пользователи", column: "createByUserId" },
  ];

  const [updateEmployerModal, setUpdateEmployerModal] = useState(false);
  const openUpdateEmployer = async () => {
    const response = await ApiService.fetchDataWithToken("/application", {
      taskId: selectedRow,
    });
    const project = response?.project || {};
    const category = response?.category || {};
    setProjectIdInput(response?.projectId || null);
    setUserId(response?.createByUserId);
    setTipIdInput(response?.categoryId || null);
    setProjectName(project.name || "");
    setTipName(category.name || "");
    setUpdateEmployerModal(true);
  };
  const handleUpdateEmployee = async () => {
    const body = {
      taskId: selectedRow,
      projectId: projectIdInput,
      categoryId: tipIdInput,
      comment: commentInput,
      userId: userId,
      time: completion_time,
    };
    try {
      await ApiService.postDataWithToken("/application/updateEmpoyer", body);
      setToastType("success");
      setToastMessage("Заявка успешно изменена");
      refreshTaskTable();
      setUpdateEmployerModal(false);
    } catch (error) {}
  };

  const handlePutOffOpen = async () => {
    setCommentInput("");
    setCompletion_time(0);
    setIsPutOffModalOpen(true);
  };

  const handlePutOffSubmit = async () => {
    const body = {
      id: selectedRow,
      comment: commentInput,
      categoryId: tipIdInput,
      completion_time: completion_time,
    };
    try {
      await ApiService.postDataWithToken("/application/putoff", body);
      setToastType("success");
      setToastMessage("Заявка успешно отложенна");
      refreshTaskTable();
      setIsPutOffModalOpen(false);
    } catch (error) {}
  };

  const openServiceModal = async () => {
    try {
      const response = await ApiService.fetchDataWithToken(
        `/enot/${selectedRow}`
      );
      const user = await ApiService.fetchDataWithToken("/user/getList");
      setItem(user);
      setServiceData(response); // Устанавливаем данные о не входящих в тариф услугах
      setIsServiceModalOpen(true); // Открываем модальное окно
    } catch (error) {}
  };

  // Функция для обработки изменения поля ввода
  const onServiceChange = (id, field, value) => {
    // Создаем копию текущих данных
    const updatedData = serviceData.map((service) => {
      // Ищем нужную услугу по id
      if (service.id === id) {
        // Обновляем только нужное поле (например, количество ЕНОТов)
        return { ...service, [field]: value };
      }
      return service;
    });
    // Обновляем состояние с новыми данными
    setServiceData(updatedData);
  };

  // Функция для подтверждения изменений в услуге
  const onConfirmService = async (id) => {
    try {
      // Ищем услугу по id
      const service = serviceData.find((service) => service.id === id);
      if (!service) return; // Если услуга не найдена, ничего не делаем

      // Проверяем, является ли услуга новой (isNew: true)
      if (service.isNew) {
        // Создаем тело запроса для создания новой услуги
        const body = {
          value: service.value,
          description: service.description,
          approved: true,
          taskId: selectedRow,
          userId: userId,
        };

        // Отправляем запрос на сервер для создания новой услуги
        const response = await ApiService.postDataWithToken("/enot/add", body);

        // Если успешно, обновляем ID услуги на новый, полученный от сервера
        const updatedData = serviceData.map((s) =>
          s.id === id ? { ...s, id: response.id, isNew: false } : s
        );
        setServiceData(updatedData);

        setToastMessage("Новая услуга успешно создана и ЕНОТы подтверждены");
      } else {
        // Услуга уже существует, отправляем запрос на сервер для обновления данных
        const body = {
          id: service.id,
          value: service.value,
          description: service.description,
          approved: true,
          taskId: service.taskId,
          userId: service.userId,
        };

        await ApiService.postDataWithToken("/enot/update", body);
        setToastMessage("ЕНОТы подтверждены");
      }
      const response = await ApiService.fetchDataWithToken(
        `/enot/${selectedRow}`
      );
      const user = await ApiService.fetchDataWithToken("/user/getList");
      setItem(user);
      setServiceData(response); // Устанавливаем данные о не входящих в тариф услугах
      setSelectedValue("");
      setToastType("success");
    } catch (error) {
      console.error("Ошибка при подтверждении изменений:", error);
      alert("Не удалось подтвердить изменения.");
    }
  };

  // Функция для удаления услуги
  const onRemoveService = async (id) => {
    try {
      await ApiService.postDataWithToken("/enot/delete", { id: id });
      const response = await ApiService.fetchDataWithToken(
        `/enot/${selectedRow}`
      );
      setServiceData(response); // Устанавливаем данные о не входящих в тариф услугах
      setSelectedValue("");
      setToastMessage("ЕНОТы удалены");
      setToastType("success");
    } catch (error) {
      console.error("Ошибка при удалении услуги:", error);
      alert("Не удалось удалить услугу.");
    }
  };

  // Функция для добавления новой услуги
  const handleAddService = () => {
    // Создаем новый объект услуги с пустыми полями
    const newService = {
      id: Date.now(), // Временный ID до сохранения в базе данных
      value: "",
      userId: null,
      description: "",
      isNew: true,
    };

    // Добавляем новую услугу в состояние
    setServiceData([...serviceData, newService]);
  };

  const [itemm, setItem] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    setSelectedValue("");
    setUserId(null);
  }, [listener]);

  return (
    <>
      <div style={{ margin: "10px" }}>
        <WorkLoadComponent
          apiUrl={"/application/table"}
          workLoad={workLoad}
          detailsConfig={detailsWorkLoad}
          setUrl={setTaskUrl}
        />
      </div>
      <div className="grid-view-container">
        <TaskTable
          listener={listener}
          apiUrl={taskUrl}
          columns={columns}
          selectedRow={selectedRow}
          setSelectedRow={setSelectRow}
        />
      </div>
      <CustomToast
        message={toastMessage}
        type={toastType}
        setMessage={setToastMessage}
        setType={setToastType}
      />
      <Modal active={isViewOpen} setActive={setIsViewOpen}>
        <View
          listener={listener}
          apiUrl={"/application/table"}
          selectedId={selectedRow}
          detailsConfig={detailsConfig}
          refreshTaskTable={refreshTaskTable}
        >
          {[
            "admin",
            "service",
            "manager",
            "sales manager",
            "bookkeeping",
          ].includes(role) && (
            <>
              <button
                onClick={() => {
                  openUpdateDeadline();
                }}
              >
                Крайний срок
              </button>
              {userItems && (
                <SmallMenu
                  items={userItems}
                  onSelectItem={handleUserSelect}
                  placeHolder={"Ответственный"}
                  listener={selectedRow}
                />
              )}
              <button
                style={{ margin: "5px" }}
                onClick={() => {
                  setModalActive(true);
                }}
              >
                Редактировать
              </button>
            </>
          )}
          {["admin"].includes(role) && (
            <button
              style={{ margin: "5px" }}
              onClick={() => {
                openServiceModal();
              }}
            >
              Подтвердить енотов
            </button>
          )}
          {["employee"].includes(role) && (
            <button
              s
              onClick={() => {
                openUpdateEmployer();
              }}
            >
              Редактировать
            </button>
          )}
          {["admin", "bookkeeping", "manager", "sales manager"].includes(
            role
          ) && (
            <button
              onClick={() => {
                setStatistic(!statistic);
              }}
            >
              Статистика
            </button>
          )}
          <button
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setCheckListOpenModal(true);
            }}
          >
            ЧекЛист
          </button>
          {statistic && <TaskFinishStatistic taskId={selectedRow} />}
          {selectedRow && projectIdInput && (
            <Chat
              taskId={selectedRow}
              listener={listener}
              params={{ projectId: projectIdInput }}
            ></Chat>
          )}
          {[
            "admin",
            "service",
            "manager",
            "sales manager",
            "bookkeeping",
          ].includes(role) && (
            <div className="lena-style">
              <button
                style={{ margin: "5px" }}
                onClick={() => {
                  handleFinishOpen();
                }}
              >
                Завершить
              </button>
              {["admin", "service"].includes(role) && (
                <button
                  style={{ margin: "5px" }}
                  onClick={() => {
                    deleteTask();
                  }}
                >
                  Удалить
                </button>
              )}
              <button
                style={{ margin: "5px" }}
                onClick={() => {
                  handlePutOffOpen();
                }}
              >
                Отложить
              </button>
              <button
                style={{ margin: "5px" }}
                onClick={() => {
                  resumeTask();
                }}
              >
                Возобновить(Завершенную)
              </button>
            </div>
          )}
        </View>
      </Modal>
      <Modal active={updateEmployerModal} setActive={setUpdateEmployerModal}>
        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
          Редактор заявки {selectedRow}
        </p>
        <div style={{ marginBottom: "10px" }}>
          <p style={{ fontWeight: "bold" }}>Отдел</p>
          <AutocompleteInput
            apiUrl={`/depart`}
            name="name"
            placeholder="*Выбрать"
            setId={setProjectIdInput}
            setName={setProjectName}
            chekValue={projectIdInput}
            validation={true}
          />
        </div>
        {projectIdInput && (
          <div style={{ marginBottom: "10px" }}>
            <p style={{ fontWeight: "bold" }}>Тэг/Тип</p>
            <AutocompleteInput
              key={projectIdInput}
              apiUrl={`/tip`}
              params={{ projectId: projectIdInput }}
              name="name"
              placeholder="*Выбрать"
              setId={setTipIdInput}
              setName={setTipName}
              chekValue={tipIdInput}
              validation={true}
            />
          </div>
        )}
        {projectIdInput && (
          <div style={{ marginBottom: "10px" }}>
            <p style={{ fontWeight: "bold" }}>Ответственный</p>
            <AutocompleteInput
              key={projectIdInput}
              apiUrl={`/user/byProject`}
              params={{ projectId: projectIdInput }}
              name="firstName"
              placeholder="*Выбрать"
              setId={setUserId}
              chekValue={userId}
              validation={true}
            />
          </div>
        )}
        <div>
          <p style={{ fontWeight: "bold" }}>Коментарий</p>
          <textarea
            ref={textareaRef2}
            type="text"
            value={commentInput}
            onChange={(e) => setCommentInput(e.target.value)}
            placeholder="Текст"
            className="input-auto-height"
          />
        </div>
        <p style={{ fontWeight: "bold" }}>
          Введите время выполнения заявки в минутах, если вы не знаете время
          предыдущего ответственного поставьте 0
        </p>
        <input
          style={{ marginTop: "10px" }}
          type="number"
          min="0"
          placeholder="0"
          value={completion_time}
          onChange={(e) => setCompletion_time(e.target.value)}
        />
        <div style={{ marginTop: "10px" }}>
          <button onClick={handleUpdateEmployee}>Отправить</button>
        </div>
      </Modal>
      <Modal active={openModelDeadline} setActive={setOpenModelDeadline}>
        <p style={{ fontSize: "18px", fontWeight: "bold" }}>
          Изменить крайний срок {selectedRow}
        </p>
        <div
          style={{
            margin: "20px",
          }}
        >
          <div>
            <textarea
              ref={textareaRef1}
              type="text"
              value={commentInput}
              onChange={(e) => setCommentInput(e.target.value)}
              placeholder="Введите комментарий"
              className="input-auto-height"
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <input
              type="datetime-local"
              name="newDeadline"
              value={newDeadline ? formatDate(newDeadline) : ""}
              onChange={handleDateChange}
            />
          </div>
          <div style={{ marginTop: "10px" }}>
            <button onClick={handleUpdateDeadline}>Отправить</button>
          </div>
        </div>
      </Modal>
      <Modal active={isServiceModalOpen} setActive={setIsServiceModalOpen}>
        <div className="service-management">
          <p>Подтвердить/Выдать ЕНОТов:</p>
          <div className="service-header">
            <div className="service-header-item">ФИО</div>
            <div className="service-header-item">ЕНОТы</div>
            <div className="service-header-item">Причина</div>
            <div></div> {/* Пустой элемент для кнопок */}
          </div>
          {serviceData.map((service, index) => (
            <div
              key={index}
              className={`service-item ${service.approved ? "approved" : ""}`}
            >
              <div className="service-item-content">
                {/* Встроенный выпадающий список для выбора пользователя */}
                {service.isNew ? (
                  <CustomSelect
                    options={itemm.map((item) => ({
                      value: item.id,
                      label:
                        item.firstName && item.lastName
                          ? `${item.firstName} ${item.lastName}`
                          : item.name,
                    }))}
                    value={selectedValue}
                    onChange={setUserId}
                    placeholder="Выберите пользователя"
                  />
                ) : (
                  service.fio
                )}
              </div>
              <div className="service-item-content">
                {/* Поле для ввода количества ЕНОТов */}
                <input
                  type="number"
                  value={service.value}
                  onChange={(e) =>
                    onServiceChange(service.id, "value", e.target.value)
                  }
                  className="input-field"
                  min={0}
                  disabled={service.approved} // Отключаем инпут, если услуга подтверждена
                />
              </div>
              <div className="service-item-content">
                {service.description} {/* Отображение причины */}
              </div>
              <div className="service-actions">
                <div className="action-buttons">
                  <button
                    onClick={() => onConfirmService(service.id)}
                    className={`icon-button confirm-button ${
                      service.approved ? "disabled" : ""
                    }`}
                    title="Подтвердить изменения"
                    disabled={service.approved} // Отключаем кнопку, если услуга подтверждена
                  >
                    <i
                      className={`fas fa-check-circle ${
                        service.approved ? "disabled-icon" : ""
                      }`}
                    ></i>
                  </button>
                  <button
                    onClick={() => onRemoveService(service.id)}
                    className="icon-button delete-button"
                    title="Удалить услугу"
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={() => handleAddService()}
          className="icon-button add-service-button"
        >
          <i className="fas fa-plus-circle"></i>
        </button>
      </Modal>
      <Modal active={isFinishModalOpen} setActive={setIsFinishModalOpen}>
        <p
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "1em" }}
        >
          Завершить заявку {selectedRow}
        </p>
        <p style={{ fontWeight: "bold" }}>Введите комментарий</p>
        <div>
          <textarea
            ref={textareaRef2}
            type="text"
            value={commentInput}
            onChange={(e) => setCommentInput(e.target.value)}
            placeholder="Текст"
            className="input-auto-height"
          />
        </div>
        <p style={{ fontWeight: "bold" }}>
          Введите время выполнения заявки в минутах
        </p>
        <input
          style={{ marginTop: "10px" }}
          type="number"
          min="0"
          placeholder="0"
          value={completion_time}
          onChange={(e) => setCompletion_time(e.target.value)}
        />
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <button onClick={handleFinishSubmit}>Завершить</button>
          <button
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setIsFinishModalOpen(false);
            }}
          >
            Отмена
          </button>
        </div>
      </Modal>
      <Modal active={isPutOffModalOpen} setActive={setIsPutOffModalOpen}>
        <p
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "1em" }}
        >
          Отложить заявку {selectedRow}
        </p>
        <p style={{ fontWeight: "bold" }}>Введите комментарий</p>
        <div>
          <textarea
            ref={textareaRef2}
            type="text"
            value={commentInput}
            onChange={(e) => setCommentInput(e.target.value)}
            placeholder="Текст"
            className="input-auto-height"
          />
        </div>
        <p style={{ fontWeight: "bold" }}>
          Введите время выполнения заявки в минутах
        </p>
        <input
          style={{ marginTop: "10px" }}
          type="number"
          min="0"
          placeholder="0"
          value={completion_time}
          onChange={(e) => setCompletion_time(e.target.value)}
        />
        <div
          style={{
            marginTop: "10px",
          }}
        >
          <button onClick={handlePutOffSubmit}>Отложить</button>
          <button
            style={{ marginLeft: "10px" }}
            onClick={() => {
              setIsPutOffModalOpen(false);
            }}
          >
            Отмена
          </button>
        </div>
      </Modal>
      <Modal active={modalActive} setActive={setModalActive}>
        <p
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "1em" }}
        >
          Редактор заявки {selectedRow}
        </p>
        <div>
          <div style={{ marginBottom: "10px" }}>
            <p style={{ fontWeight: "bold" }}>Отдел</p>
            <AutocompleteInput
              apiUrl={`/depart`}
              name="name"
              placeholder="*Выбрать"
              setId={setProjectIdInput}
              setName={setProjectName}
              chekValue={projectIdInput}
              validation={true}
            />
          </div>
          {projectIdInput && (
            <div style={{ marginBottom: "10px" }}>
              <p style={{ fontWeight: "bold" }}>Тэг/Тип</p>
              <AutocompleteInput
                key={projectIdInput}
                apiUrl={`/tip`}
                params={{ projectId: projectIdInput }}
                name="name"
                placeholder="*Выбрать"
                setId={setTipIdInput}
                setName={setTipName}
                chekValue={tipIdInput}
                validation={true}
              />
            </div>
          )}
          <div style={{ marginBottom: "10px" }}>
            <p style={{ fontWeight: "bold" }}>Клиент</p>
            <AutocompleteInput
              apiUrl={`/firm`}
              name="name"
              placeholder="*Выбрать"
              setId={setFirmIdInput}
              setName={setCompanyName}
              chekValue={firmIdInput}
              validation={!isChecked}
            />
          </div>
          {firmIdInput && (
            <div style={{ marginBottom: "10px" }}>
              <p style={{ fontWeight: "bold" }}>Филиал</p>
              <AutocompleteInput
                key={firmIdInput}
                apiUrl={`/firm/${firmIdInput}/division`}
                name="adres"
                placeholder="*Выбрать"
                setId={setDivisionId}
                setName={setDivisionName}
                chekValue={divisionId}
                validation={false}
              />
            </div>
          )}
          {divisionId && (
            <div style={{ marginBottom: "10px" }}>
              <p style={{ fontWeight: "bold" }}>ПК</p>
              <AutocompleteInput
                key={divisionId}
                apiUrl={`/firm/${firmIdInput}/division/${divisionId}/pc`}
                name="number"
                placeholder="*Выбрать"
                setId={setPCId}
                setName={setPCName}
                chekValue={PCId}
                validation={false}
              />
            </div>
          )}
          {divisionId && (
            <div style={{ marginBottom: "10px" }}>
              <p style={{ fontWeight: "bold" }}>ОРГ</p>
              <AutocompleteInput
                key={divisionId}
                apiUrl={`/firm/${firmIdInput}/division/${divisionId}/org`}
                name="number"
                placeholder="*Выбрать"
                setId={setORGId}
                setName={setORGName}
                chekValue={ORGId}
                validation={false}
              />
            </div>
          )}

          <div style={{ marginBottom: "10px" }}>
            <p style={{ fontWeight: "bold" }}>Название заявки(кратко)</p>
            <input
              type="text"
              value={nameInput}
              placeholder="Текст"
              onFocus={() => {
                setFocus(true);
              }}
              onBlur={() => {
                setFocus(false);
              }}
              style={{
                borderColor: nameInput === "" ? "red" : "", // Изменяем цвет рамки в случае ошибки валидации
                boxShadow: isFocus && nameInput === "" ? "0 0 5px red" : "",
              }}
              maxLength={200}
              onChange={(e) => setNameInput(e.target.value)}
            />
            {nameInput.length > 199 && (
              <p
                style={{
                  color: "rgb(211, 0, 0)",
                  fontSize: "12px",
                  height: "15px",
                  margin: "0px",
                  visibility: nameInput.length > 199 ? "visible" : "hidden",
                }}
              >
                Вы достигли ограничения по символам ({200})
              </p>
            )}
            <div style={{ position: "relative" }}>
              <p
                style={{
                  color: "rgb(211, 0, 0)",
                  fontSize: "12px",
                  height: "15px",
                  margin: "0px",
                  visibility: nameInput !== "" ? "hidden" : "visible",
                }}
              >
                Обязательное поле
              </p>
            </div>
          </div>
          <div style={{ marginTop: "10px" }}>
            <p style={{ fontWeight: "bold" }}>Описание заявки(подробно)</p>
            <input
              type="text"
              value={description}
              placeholder="Текст"
              maxLength={2000}
              onChange={(e) => setDescription(e.target.value)}
            />
          </div>
          {description.length > 1999 && (
            <p
              style={{
                color: "rgb(211, 0, 0)",
                fontSize: "12px",
                height: "15px",
                margin: "0px",
                visibility: description.length > 1999 ? "visible" : "hidden",
              }}
            >
              Вы достигли ограничения по символам ({2000})
            </p>
          )}
          <div style={{ marginTop: "20px" }}>
            <p style={{ fontWeight: "bold" }}>Приоритет</p>
            <AutocompleteInput
              apiUrl={`/priority`}
              name="name"
              placeholder="*Выбрать"
              setId={setPriorityIdInput}
              setName={setPriorityName}
              chekValue={priorityName}
              validation={true}
              id={setSelectRow}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button className="button" onClick={updateTask}>
              Отправить
            </button>
            <label className="checkbox-container">
              <input
                style={{ height: "30px", width: "30px" }}
                type="checkbox"
                className="checkbox-input"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
              <span className="checkmark"></span>
              <span style={{ fontSize: "18px" }}>Разовая</span>
            </label>
          </div>
        </div>
      </Modal>
      <Modal active={openDeparture} setActive={setOpenDeparture}>
        <p
          style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "20px" }}
        >
          У этого клиента есть еще заявки. Может стоить переназначить
          ответственного?
        </p>
        <TaskTable
          listener={listener}
          apiUrl={departureUrl}
          columns={columns}
          selectedRow={selectedRow}
          setSelectedRow={setSelectRow}
          buttonOff={true}
        />
      </Modal>
      <SideModal active={checkListOpenModal} setActive={setCheckListOpenModal}>
        <CheckList apiUrl={"/cheklist"} taskId={selectedRow} />
      </SideModal>
    </>
  );
};

export default TaskGrid;
