import React, { useState, useEffect, useMemo } from 'react';
import ApiService from '../../components/ApiService';
import CustomToast from '../../components/CustomToast';
import YearMonthSelector from '../../components/YearMonthSelector';

const WorkersWork = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [tableData, setTableData] = useState([]);
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);

  const MounthStart = useMemo(() => {
    const MounthStart = new Date(selectedYear, selectedMonth, 1);
    MounthStart.setHours(0, 0, 0, 0);
    return MounthStart;
  }, [selectedYear, selectedMonth]);

  const MounthEnd = useMemo(() => {
    const MounthEnd = new Date(selectedYear, selectedMonth + 1, 0);
    MounthEnd.setHours(23, 59, 59, 999);
    return MounthEnd;
  }, [selectedYear, selectedMonth]);

  useEffect(() => {
    document.title = 'Слежка';
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    const reloadPage = setTimeout(() => {
      window.location.reload();
    }, 60 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
      clearTimeout(reloadPage);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [response] = await Promise.all([
          ApiService.fetchDataWithToken('/user/workers', { startDate: MounthStart, endDate: MounthEnd }),
        ]);
        const transformedData = transformData(response);
        setTableData(transformedData);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [MounthStart, MounthEnd]);

  const transformData = (data) => {
    const transformed = {};

    data.forEach((item) => {
      const fullName = `${item['Имя']} ${item['Фамилия']}`;
      const date = new Date(item['Начало рабочего дня']);
      console.log(date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }))
      const day = date.getDate(); // Получаем день месяца

      if (!transformed[fullName]) {
        transformed[fullName] = { 'Имя': item['Имя'], 'Фамилия': item['Фамилия'], 'Дни': {} };
      }

      transformed[fullName]['Дни'][day] = {
        type: item['Выходной день'] ? 'Выходной' : 'Рабочий',
        time: item['Выходной день'] ? null : date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
      };
    });

    return Object.entries(transformed).map(([key, value]) => value);
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysInMonth = useMemo(() => getDaysInMonth(selectedYear, selectedMonth), [selectedYear, selectedMonth]);

  // Функция для проверки, больше ли времени 9:15
  const isLate = (timeString) => {
    if (!timeString) return false;
    const [hours, minutes] = timeString.split(':').map(Number);
    return hours > 9 || (hours === 9 && minutes > 15);
  };

  return (
    <div>
      <div style={{ margin: '10px' }}>
        Текущая дата: {currentDate.toLocaleString()}
      </div>
      <div style={{ margin: '10px' }}>
        <YearMonthSelector
          selectedYear={selectedYear}
          selectedMonth={selectedMonth}
          onYearChange={setSelectedYear}
          onMonthChange={setSelectedMonth}
        />
      </div>
      <div className="kpi-tasks-table-container">
        <div className='table-wrapper'>
          <table className="table">
            <thead>
              <tr>
                <th>Имя</th>
                <th>Фамилия</th>
                {Array.from({ length: daysInMonth }, (_, index) => (
                  <th key={index + 1}>{index + 1}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  <td>{row['Имя']}</td>
                  <td>{row['Фамилия']}</td>
                  {Array.from({ length: daysInMonth }, (_, index) => {
                    const dayData = row['Дни'][index + 1];
                    const isLateArrival = dayData?.type === 'Рабочий' && isLate(dayData.time);

                    return (
                      <td
                        key={index + 1}
                        style={{
                          color: dayData?.type === 'Выходной' ? 'yellow' :
                                 isLateArrival ? 'red' :
                                 'green'
                        }}
                      >
                        {dayData?.time || (dayData?.type === 'Выходной' ? 'Выходной' : '-')}
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
    </div>
  );
};

export default WorkersWork;
