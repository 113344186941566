export const START_PAGE = "/";
export const COMPANY_CHECK_PAGE = "/companyCheck/:id";
export const COMPANY_CHECK_PAGE_NOT_ID = "/companyCheck/";
export const COMPANY_PAGE = "/company/:id";
export const COMPANY_PAGE_NOT_ID = "/company/";
export const TASK_STATISTIC = "/cashboxTaskStatistic";
export const USER_STATISTIC = "/workersStatisticTags";
export const COMPANY_STATISTIC = "/companyStatisticTags";
export const WORKERS_STATISTIC = "/workersStatistic";
export const TRACKING_PAGE = "/tracking/:id";
export const TRACKING_PAGE_NOT_ID = "/tracking";
export const DIVISION_PAGE = "/company/:companyId/division/:id";
export const DIVISION_PAGE_NOT_ID = "/company/:companyId/division/";
export const PC_COMPANY_PAGE_NOT_ID = "/company/:companyId/pc/";
export const PC_COMPANY_PAGE = "/company/:companyId/pc/:id";
export const CASHBOX_COMPANY_PAGE_NOT_ID = "/company/:companyId/cashbox/";
export const CASHBOX_COMPANY_PAGE = "/company/:companyId/cashbox/:id";
export const ORG_PAGE = "/company/:companyId/division/:divisionId/org/:id";
export const ORG_PAGE_NOT_ID = "/company/:companyId/division/:divisionId/org/";
export const ORG_COMPANY_PAGE = "/company/:companyId/org/:id";
export const ORG_COMPANY_PAGE_NOT_ID = "/company/:companyId/org/";
export const NETWORK_EQUIPMENT_PAGE =
  "/company/:companyId/division/:divisionId/networkEquipment/:id";
export const NETWORK_EQUIPMENT_NOT_ID =
  "/company/:companyId/division/:divisionId/networkEquipment/";
export const CLIENT_EQUIPMENT_PAGE =
  "/company/:companyId/division/:divisionId/client/:id";
export const CLIENT_EQUIPMENT_NOT_ID =
  "/company/:companyId/division/:divisionId/client/";
export const CLIENT_COMPANY_EQUIPMENT_PAGE = "/company/:companyId/client/:id";
export const CLIENT_COMPANY_EQUIPMENT_NOT_ID = "/company/:companyId/client/";
export const SERVER_PAGE =
  "/company/:companyId/division/:divisionId/server/:id";
export const SERVER_NOT_ID = "/company/:companyId/division/:divisionId/server/";
export const SERVER_COMPANY_PAGE = "/company/:companyId/server/:id";
export const SERVER_COMPANY_NOT_ID = "/company/:companyId/server/";
export const PC_EQUIPMENT_PAGE =
  "/company/:companyId/division/:divisionId/pc/:id";
export const PC_EQUIPMENT_NOT_ID =
  "/company/:companyId/division/:divisionId/pc/";
export const USER_PAGE = "/user/:id";
export const CATEGORY_USER = "/categoryUser";
export const ATTENDANCE_SHEET = "/sheet/attendance";
export const HOLIDAY_SHEET = "/sheet/holiday";
export const USER_PAGE_NOT_ID = "/user";
export const PROJECT_CATEGORY = "/projectCategory";
export const PROJECT_USER = "/projectUser";
export const USER_ADMIN_PAGE_NOT_ID = "admin/user";
export const USER_ADMIN_PAGE = "admin/user/:id";
export const REG_PAGE = "/registration";
export const TASK_TIME_CHECK = "/bookkeeping/:companyId/check/:id";
export const TASK_TIME_CHECK_NOT_ID = "/bookkeeping/:companyId";
export const TASK_TIME = "/bookkeeping";
export const AUDIT = "/audit";
export const BUFFER_NOT_ID = "/bufer";
export const BUFFER = "/bufer/:id";
export const ENOTS = "/enots/";
export const KPI_PAGE = "/kpi";
export const TASK_PAGE = "/ktasks/:id";
export const TASK_PAGE_NOT_ID = "/ktasks";
export const CASHBOX_PAGE =
  "/company/:companyId/division/:divisionId/cashbox/:id";
export const CASHBOX_PAGE_NOT_ID =
  "/company/:companyId/division/:divisionId/cashbox";
export const TARIFF_PAGE = "/tariff/:id";
export const TARIFF_PAGE_NOT_ID = "/tariff";
export const OFD_PAGE = "/ofd/:id";
export const OFD_PAGE_NOT_ID = "ofd";
export const DEPART_PAGE = "/depart/:id";
export const DEPART_PAGE_NOT_ID = "/depart";
export const CATIGORIES_PAGE = "/categories/:id";
export const CATIGORIES_PAGE_NOT_ID = "/categories";
export const TASK_PROJECT = "/trackingUser";
export const KPI_CALCULATION = "/calculation";
export const COMPANY_CATEGORY = "/companyCategory";
export const COMPANY_TIME = "/companyTime";
export const AUTHORIZATION = "/authorization";
export const CLIENT_ACCESS = "clientaccess/:id";
export const CLIENT_ACCESS_NOT_ID = "clientaccess";
