import React, { useState, useEffect } from 'react';
import AutocompleteInput from '../components/autoCompliteInputs/FithoutCreate';
import CustomToast from '../components/CustomToast';
import ApiService from '../components/ApiService';
const FormApp = () => {

  // Состояние для управления значениями полей
  const [nameInput, setNameInput] = useState('');
  const [commentInput, setCommentInput] = useState('');
  const [projectIdInput, setProjectIdInput] = useState(null);
  const [projectName, setProjectName] = useState('');
  const [firmIdInput, setFirmIdInput] = useState(null);
  const [companyName, setCompanyName] = useState('');
  const [priorityName, setPriorityName] = useState('');
  const [tipIdInput, setTipIdInput] = useState(null);
  const [tipIdName, setTipName] = useState('');
  const [usrIdInput, setUsrIdInput] = useState(null);
  const [usrName, setUsrName] = useState('');
  const [toastMessage, setToastMessage] = useState(null);
  const [toastType, setToastType] = useState(null);
  const [priorityIdInput, setPriorityIdInput] = useState('');
  const [isFocus, setFocus] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [divisionId, setDivisionId] = useState(null);
  const [ORGId, setORGId] = useState(null);
  const [ORGName, setORGName] = useState('');
  const [clientChatId, setСlientChatId] = useState('');
  const [PCId, setPCId] = useState(null);
  const [divisionName, setDivisionName] = useState('');
  const [PCName, setPCName] = useState('');

  useEffect(() => {
    document.title = 'Форма';
    ApiService.setToastHandlers(setToastMessage, setToastType);
  }, []);
  const handleButtonClick = async () => {
    setDisabled(true)
    const body = {
      name: nameInput,
      description: commentInput,
      priority: priorityName,
      projectId: projectIdInput,
      companyId: firmIdInput,
      divisionId: divisionId,
      pcId: PCId,
      ORGId: ORGId,
      categoryId: tipIdInput,
      companyName: companyName,
      tipName: tipIdName,
      usrName: usrName,
      projectName: projectName,
      clientChatId:clientChatId,
      userId: usrIdInput,
      categoryName: tipIdName,
      oneTime: isChecked,
    }
    try {
      let response
      if (!isChecked) {
        response = await ApiService.postDataWithToken('/application/add', body);
      } else {
        response = await ApiService.postDataWithToken('/application/addOneTime', body);
      }

      if (response.response === "Заявка отправлена") {
        setToastType('success');
        setToastMessage('Заявка успешно отправлена');
        setCommentInput('');
        setTipIdInput(null);
        setProjectIdInput('');
        setFirmIdInput(null);
        setCompanyName('');
        setPriorityName('');
        setPriorityIdInput(null);
        setIsChecked(false);
        setСlientChatId('')
        setNameInput('');
        setUsrIdInput(null);
        setDivisionId(null);
        setORGId(null);
        setORGName('');
        setPCId(null);
        setDivisionName('');
        setPCName('');
        setDisabled(false)
      }
    } catch (error) {
      setDisabled(false)
    }
  };
  useEffect(() => {
    setTipIdInput(null)
  }, [projectIdInput]);

  useEffect(() => {
    setDivisionId(null)
  }, [firmIdInput]);

  return (
    <div>
      <div className="App" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <header className="App-header" >
        </header>
        <svg className='logo-neon' style={{ width: '200px', height: '200px', margin: '30px auto' }} xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 37 37">
          <g clipPath="url(#clip0_6_17)">
            <path className="path" d="M7.52768 11.9735L3.40221 7.77979C1.13192 10.9045 0 14.4783 0 18.5005C0 22.5226 1.13192 26.0955 3.40221 29.2202L7.43173 25.124C6.22325 23.1753 5.61624 21.0034 5.61716 18.6055C5.61624 16.1851 6.25369 13.9747 7.52675 11.9735H7.52768Z" fill="#B6E33E" />
            <path className="path" d="M32.9918 7.77979L28.9642 11.8751C30.1726 13.8238 30.7787 15.9975 30.7787 18.3917C30.7769 20.813 30.1422 23.0271 28.8691 25.0283L32.9918 29.2192C35.2621 26.0946 36.3968 22.5198 36.3968 18.4995C36.3968 14.4793 35.2612 10.9045 32.9918 7.77979Z" fill="#B6E33E" />
            <path className="path" d="M18.0933 31.2878C15.7354 31.2897 13.5988 30.6736 11.6819 29.4432L7.65234 33.5394C10.7271 35.8473 14.2437 36.9998 18.2003 36.9998C22.157 36.9998 25.6699 35.8464 28.7437 33.5394L24.6182 29.3457C22.6496 30.6398 20.4743 31.2869 18.0942 31.2869L18.0933 31.2878Z" fill="#B6E33E" />
            <path className="path" d="M18.1993 0C14.2427 0 10.7261 1.15347 7.65137 3.4604L11.7759 7.65132C13.7445 6.35719 15.9198 5.71013 18.3036 5.71013C20.6873 5.71013 22.798 6.32531 24.715 7.55567L28.7427 3.4604C25.6689 1.15347 22.1541 0 18.1993 0Z" fill="#B6E33E" />
            <path className="path" d="M23.6623 9.66305V26.8384H20.8865V20.5816H18.2075L14.1282 26.8384H10.9907L15.2868 20.3359C14.1761 19.9927 13.2629 19.3418 12.547 18.3853C11.8311 17.4288 11.4732 16.3607 11.4732 15.1828C11.4732 13.6458 11.9999 12.3404 13.0544 11.2695C14.1079 10.1985 15.3911 9.66211 16.904 9.66211H23.6623V9.66305ZM16.904 18.0543H20.8865V12.3123H16.904C16.1642 12.3123 15.5359 12.5908 15.0212 13.1469C14.5064 13.703 14.249 14.3819 14.249 15.1837C14.249 15.9855 14.5064 16.6645 15.0212 17.2206C15.5359 17.7767 16.1632 18.0552 16.904 18.0552V18.0543Z" fill="#2C7136" />
          </g>
        </svg>
        <div className="headerMiniTitle" style={{ margin: '0' }} >Оставьте заявку одному из сотрудников нашей компании, указав свои контактные данные, а так же подробное описание заявки. !!!! ВАЖНО *Компания и Название Заявки (кратко) пример заполнения: "ООО РК не работает ПК"</div>
        <div className='form-container'>
          <div style={{ marginBottom: "10px" }}>
            <AutocompleteInput
              apiUrl={`/depart`}
              name='name'
              placeholder="*Отдел"
              setId={setProjectIdInput}
              setName={setProjectName}
              chekValue={projectIdInput}
              validation={true}
            />
          </div>
          {projectIdInput && <div style={{ marginBottom: "10px" }}>
            <AutocompleteInput
              key={projectIdInput}
              apiUrl={`/tip`}
              params={{ projectId: projectIdInput }}
              name='name'
              placeholder="Тип заявки"
              setId={setTipIdInput}
              setName={setTipName}
              chekValue={tipIdInput}
              validation={true}
            />
          </div>}
          {<div style={{ marginBottom: "10px" }}>
            <AutocompleteInput
              apiUrl={`/firm`}
              name='name'
              placeholder="*Название компании"
              setId={setFirmIdInput}
              setName={setCompanyName}
              chekValue={firmIdInput}
              validation={!isChecked}
            />
          </div>}
          {firmIdInput && <div style={{ marginBottom: "10px" }}>
            <AutocompleteInput
              key={firmIdInput}
              apiUrl={`/firm/${firmIdInput}/division`}
              name='adres'
              placeholder="Филиал"
              setId={setDivisionId}
              setName={setDivisionName}
              chekValue={divisionId}
              validation={false}
            />
          </div>}
          {divisionId && <div style={{ marginBottom: "10px" }}>
            <AutocompleteInput
              key={divisionId}
              apiUrl={`/firm/${firmIdInput}/division/${divisionId}/pc`}
              name='number'
              placeholder="ПК"
              setId={setPCId}
              setName={setPCName}
              chekValue={PCId}
              validation={false}
            />
          </div>}
          {divisionId && <div style={{ marginBottom: "10px" }}>
            <AutocompleteInput
              key={divisionId}
              apiUrl={`/firm/${firmIdInput}/division/${divisionId}/org`}
              name='number'
              placeholder="ОРГ"
              setId={setORGId}
              setName={setORGName}
              chekValue={ORGId}
              validation={false}
            />
          </div>}

          <div style={{ marginBottom: "10px" }}>
            <input
              type="text"
              value={nameInput}
              placeholder="Название заявки (кратко)"
              onFocus={() => { setFocus(true) }}
              onBlur={() => { setFocus(false) }}
              style={{
                borderColor: nameInput == '' ? 'red' : '', // Изменяем цвет рамки в случае ошибки валидации
                boxShadow: isFocus && nameInput == '' ? '0 0 5px red' : '',
              }}
              maxLength={200}
              onChange={(e) => setNameInput(e.target.value)} />
            {nameInput.length > 199 && (
              <p style={{
                color: 'rgb(211, 0, 0)',
                fontSize: '12px',
                height: '15px',
                margin: '0px',
                visibility: (nameInput.length > 199) ? 'visible' : 'hidden',
              }}>
                Вы достигли ограничения по символам ({200})
              </p>
            )}
            <div style={{ position: "relative" }}>
              <p
                style={{
                  color: 'rgb(211, 0, 0)',
                  fontSize: '12px',
                  height: '15px',
                  margin: '0px',
                  visibility: (nameInput !== '') ? 'hidden' : 'visible',
                }}
              >
                Обязательное поле
              </p>
            </div>
          </div>
          {projectIdInput && <div style={{ marginBottom: "10px" }}>
            <AutocompleteInput
              apiUrl={`/user/byProject`}
              params={{ projectId: projectIdInput }}
              name='firstName'
              placeholder="*ФИО"
              setId={setUsrIdInput}
              setName={setUsrName}
              chekValue={usrIdInput}
              validation={false}
            />
          </div>}
          <div style={{ marginBottom: "20px" }}>
            <textarea
              value={commentInput}
              placeholder="Заявка (подробно)"
              onChange={(e) => setCommentInput(e.target.value)}
              style={{
                width: '100%',
                height: 'auto',
                minHeight: '50px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '16px', // необязательно, но можно указать
              }}
              maxLength={2000}
            />
            {commentInput.length > 1999 && (
              <p style={{
                color: 'rgb(211, 0, 0)',
                fontSize: '12px',
                height: '15px',
                margin: '0px',
                visibility: (commentInput.length > 1999) ? 'visible' : 'hidden',
              }}>
                Вы достигли ограничения по символам ({2000})
              </p>
            )}
          </div>
          <div style={{ marginBottom: "20px" }}>
            <textarea
              value={clientChatId}
              placeholder="Номер телефона клиента"
              onChange={(e) => setСlientChatId(e.target.value)}
              style={{
                width: '100%',
                height: 'auto',
                minHeight: '50px',
                fontFamily: 'Arial, sans-serif',
                fontSize: '16px',
              }}
              maxLength={2000}
            />
            {commentInput.length > 1999 && (
              <p style={{
                color: 'rgb(211, 0, 0)',
                fontSize: '12px',
                height: '15px',
                margin: '0px',
                visibility: (commentInput.length > 1999) ? 'visible' : 'hidden',
              }}>
                Вы достигли ограничения по символам ({2000})
              </p>
            )}
          </div>
          <div style={{ marginBottom: "10px" }}>
            <AutocompleteInput
              apiUrl={`/priority`}
              name='name'
              placeholder="*Приоритет"
              setId={setPriorityIdInput}
              setName={setPriorityName}
              chekValue={priorityIdInput}
              validation={true}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <button
              className="button"
              disabled={disabled}
              isLoading={false}
              onClick={handleButtonClick}
            >
              Отправить
            </button>
            <label className="checkbox-container">
              <input
                style={{ height: '30px', width: '30px' }}
                type="checkbox"
                className="checkbox-input"
                checked={isChecked}
                onChange={() => setIsChecked(!isChecked)}
              />
              <span className="checkmark"></span>
              <span style={{ fontSize: '18px' }}>Разовая</span>
            </label>
          </div>

        </div>
        <CustomToast message={toastMessage} type={toastType} setMessage={setToastMessage} setType={setToastType} />
      </div>
    </div>
  );
};
export default FormApp;