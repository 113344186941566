import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import ApiService from "../ApiService";
import "../../assets/styles/header.scss";
import defaultAvatar from "../../assets/images/avatar.png"; // Замените на путь к вашему изображению аватара по умолчанию
import axios from "axios";
const apiKey = process.env.REACT_APP_API_KEY;

const SalesManagerHeader = () => {
  const [avatar, setAvatar] = useState(defaultAvatar);
  const location = useLocation();

  useEffect(() => {
    const storedAvatar = localStorage.getItem("avatar");
    if (storedAvatar) {
      setAvatar(storedAvatar);
    } else {
      fetchAvatarFromServer();
    }
  }, []);

  const fetchAvatarFromServer = async () => {
    try {
      const users = await ApiService.fetchDataWithToken("/user/byToken");
      const body = { type: "logo", userId: users.id };
      const token = localStorage.getItem("token");
      const response = await axios.post(`${apiKey}/user/download`, body, {
        responseType: "blob",
        headers: { Authorization: `Bearer ${token}` },
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64data = reader.result;
        setAvatar(base64data);
        localStorage.setItem("avatar", base64data);
      };
      reader.readAsDataURL(response.data);
    } catch (error) {
      console.error("Ошибка при загрузке аватара с сервера:", error);
    }
  };

  const handleAvatarChange = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("userId", localStorage.getItem("userId"));
    formData.append("type", "backgroundImage");
    formData.append("files", file);

    try {
      await ApiService.postDataWithToken("/user/upload", formData);
      localStorage.removeItem("backgroundImage");
      window.location.reload();
    } catch (error) {
      console.error("Ошибка при загрузке файла:", error);
    }
  };

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  return (
    <header className="custom-header">
      <div className="container-header">
        <Link to="/" className="logo-link">
          <img src={logo} alt="Logo" className="logo" />
        </Link>
        <div className="hamburger-menu">
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
        <nav className="navbar">
          <ul className="nav-list">
            <li
              className={`nav-item ${
                location.pathname === "/user" ? "active" : ""
              }`}
            >
              <Link
                to="/user"
                className={`nav-link ${
                  location.pathname.startsWith("/user") ? "active" : ""
                }`}
              >
                Личная страница{" "}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/ktasks"
                className={`nav-link ${
                  location.pathname === "/ktasks" ? "active" : ""
                }`}
              >
                Заявки
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/"
                className={`nav-link ${
                  location.pathname === "/" ? "active" : ""
                }`}
              >
                Форма
              </Link>
            </li>
            <li className="nav-item">
              {" "}
              <Link
                to="/tracking"
                className={`nav-link ${
                  location.pathname === "/tracking" ? "active" : ""
                }`}
              >
                Трэкинг
              </Link>
            </li>
            <li className="nav-item">
              {" "}
              <Link
                to="/company"
                className={`nav-link ${
                  location.pathname === "/company" ? "active" : ""
                }`}
              >
                Клиенты
              </Link>{" "}
            </li>
            <li className="nav-item">
              <Link
                to="/tariff"
                className={`nav-link ${
                  location.pathname === "/tariff" ? "active" : ""
                }`}
              >
                Тарифы
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/enots"
                className={`nav-link ${
                  location.pathname.startsWith("/enots") ? "active" : ""
                }`}
              >
                {" "}
                Еноты
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/companyCheck"
                className={`nav-link ${
                  location.pathname === "/companyCheck" ? "active" : ""
                }`}
              >
                Отслеживание времени(которое отписывают сотрудники)
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/sheet/holiday" className="nav-link">
                Отпуска
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/sheet/attendance" className="nav-link">
                ГЗ месяца
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/audit"
                className={`nav-link ${
                  location.pathname === "/audit" ? "active" : ""
                }`}
              >
                Аудит
              </Link>
            </li>
          </ul>
        </nav>
        <div className="user-menu">
          <img src={avatar} alt="User Avatar" className="mini-avatar" />
          <ul className="dropdown-user-menu">
            <li className="nav-item">
              <button onClick={logOut}>Выйти</button>
            </li>
            <li className="nav-item">
              <input
                type="file"
                id="avatar-upload"
                onChange={handleAvatarChange}
                style={{ display: "none" }}
              />
              <label htmlFor="avatar-upload" className="file-upload-label">
                Загрузить фон
              </label>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default SalesManagerHeader;
