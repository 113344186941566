import React, { useEffect, useState } from "react";
import ApiService from "../ApiService";

const Control = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.fetchDataWithToken(
          "/application/table?search=8&column=projectid&checkDeadline=false&comment=false&like=false&page=1&itemsPerPage=5000"
        );
        setData(response);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("ru-RU", options);
  };

  const isDeadlineSoon = (deadline) => {
    const deadlineDate = new Date(deadline);
    const now = new Date();
    const oneMonthFromNow = new Date(now.setMonth(now.getMonth() + 1));
    return deadlineDate < oneMonthFromNow;
  };

  return (
    <div className="average-time-task">
      <h2>Профилактика</h2>
      <div className="table-wrapper">
        {data ? (
          <table
            className="table"
            style={{
              boxShadow: "0 0px 8px rgba(0, 0, 0, 0.3)",
              borderRadius: "8px",
            }}
          >
            <thead>
              <tr style={{ borderRadius: "8px" }}>
                <th style={{ width: "75%" }}>Имя задачи</th>
                <th>Крайний срок</th>
              </tr>
            </thead>
            <tbody>
              {data.tasks.map((task) => {
                const rowStyle = isDeadlineSoon(task.deadline)
                  ? { backgroundColor: "#ffcccb", color: "black" }
                  : {};
                return (
                  <tr key={task.id} style={rowStyle}>
                    <td>{task.name}</td>
                    <td>
                      {task.deadline ? formatDate(task.deadline) : "Не указано"}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>Нет данных для отображения.</p>
        )}
      </div>
    </div>
  );
};

export default Control;
